import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {useSelector} from 'react-redux'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import DashboardComponent from '../containers/Dashboard/component';
import { isAdminAuth, logout } from '../utils/auth';

export default function Dashboard() {
        
        const [open, setOpen] = useState(false);
        let history = useHistory();
        const {auth} = useSelector((state)=> state)
        
        useEffect(() => {
            let a = isAdminAuth()
            let b = auth.isFetching
            console.log('AUTH=>',auth, a, typeof a, b, typeof b)
            
            if (b) {
                setOpen(true)
            }
            else if (!b) {
                if(a) {
                    setOpen(false)
                }
                else if(!a) {
                    console.log(a)
                    logout()
                    return window.location.replace('/')
                }
            } 
          }, [auth, history]);

        return (
            <div>

                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={open}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <DashboardComponent><div>{/*Throw Child Bodies In Here */}</div></DashboardComponent>
            </div>  
        )
}