import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import {useHistory} from 'react-router-dom'
import moment from 'moment'
import _ from 'lodash'
import axios from 'axios';
import { authCode } from '../../../utils/auth';
import {  Container,Typography, Stack, Button, Tooltip, Box, Dialog,DialogActions,DialogContent,DialogContentText, DialogTitle, Collapse, Modal, TextField } from '@mui/material';
import { Alert, Table, TableBody, TableCell, TableRow } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {DataGrid, GridAddIcon, GridToolbarContainer,GridToolbarColumnsButton, GridToolbarFilterButton,GridToolbarDensitySelector, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { CloudUpload } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import NewProductFields from '../../Forms/newproductfields';
import { useForm, FormProvider } from 'react-hook-form';


const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const usdPrice = {
  width: 130,
  valueFormatter: ({ value }) => currencyFormatter.format(value),
  cellClassName: 'font-tabular-nums',
};

function EditToolbar() {
  
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [loading, setLoading] = useState(false);

  const methods = useForm({
    defaultValues: {
      product_name: '',
      description: '',
      pts_value: '',
      status: false,
      vendor: '',
      v_item_number: '',
      category: '',
      dollar_value: '',
      size: '',
      color: '',
      is_limited_qty: false,
      limited_qty: '',
    },
  });

  const { handleSubmit, reset, control, watch } = methods;
  // const watchAllFields = watch();
  
  // useEffect(() => {
  //   const subscription = watch((value, { name, type }) =>
  //     console.log('watch:', value, name, type)
  //   );
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  const openNewProductModal = () => {
    setOpenAddProduct(true);
  }

  const closeNewProductModal = () => {
    setOpenAddProduct(false);
    window.location.reload(); 
  };

  const createNewProductAPI = async (data) => {
    console.log('Form Data:', data);
    let token = JSON.parse(authCode()).accessToken
    setLoading(true);
    try {

      const response = await axios.post(process.env.REACT_APP_EXTRAMILE_URL + '/admin/products/add', data, {
        headers: {
            'content-type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
      })

      if (response.status === 200 || response.status === 201) {
        reset()
        closeNewProductModal();
      }
    } catch (error) {
      console.error('Error while submitting form data:', error);
    } finally {
      setLoading(false);
      closeNewProductModal();
    }
  };

  return (
    <GridToolbarContainer>
      <GridToolbarQuickFilter />
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <Button color="primary" startIcon={<GridAddIcon />} onClick={openNewProductModal}>
        Add New Product
      </Button>

      <Dialog open={openAddProduct} onClose={closeNewProductModal} sx={{ minWidth: 750 }}>
        <DialogTitle>Add A New Product</DialogTitle>
        <DialogContent sx={{ minWidth: 500 }}>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(createNewProductAPI)}>
              <NewProductFields />
            </form>
          </FormProvider>
        </DialogContent>
        <DialogActions>
          {loading ? null : (
            <Button onClick={closeNewProductModal} sx={{ color: '#9b9b9b' }}>
              Cancel
            </Button>
          )}
          <LoadingButton
            loading={loading}
            onClick={handleSubmit(createNewProductAPI)}
            loadingIndicator="Adding Product ..."
            startIcon={<SaveIcon />}
          >
            Add Product
          </LoadingButton>
        </DialogActions>
    </Dialog>
    </GridToolbarContainer>
  );
}

function CloudImageUpload(props) {
  const { source, productId, onUpload, loadingStatus } = props;
  const inputRef = useRef();

  const handleUploadClick = () => {
    inputRef.current.value = ''
    inputRef.current.click()
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('productId', productId);
      loadingStatus(true)
      try {
        let token = JSON.parse(authCode()).accessToken;
        await axios.post(process.env.REACT_APP_EXTRAMILE_URL + '/admin/products/image/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
          }
        }).then((response) => {
          inputRef.current.value = '';
          onUpload();
          loadingStatus(false)
          return response
        }).catch((error) => {
          loadingStatus(false)
          console.error(error);
        });
      } catch (error) {
        loadingStatus(false)
        console.error('File upload failed:', error);
      }
    }
  };
  

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      height="100%"
      px={1}
      py={1}
    >
      <Box flexGrow={1} display="flex" justifyContent="center" alignItems="center" width="100%">
        <img src={source} alt="Your alt text" style={{ maxHeight: '100px', maxWidth: '100px', objectFit:'contain' }} />
      </Box>
      <Button
        color="primary"
        size="small"
        startIcon={<CloudUpload />}
        onClick={handleUploadClick}
      >
        Upload
      </Button>
      <input
        hidden
        accept="image/*"
        type="file"
        ref={inputRef}
        onChange={handleFileUpload}
      />
    </Box>
  );
}


function DescriptionComponent({row}) {
  const [description, setDescription] = useState('');
  const [openDescModal, setDescModalOpen] = useState(false);
  const [loadDescUpdate, setLoadDescUpdate] = useState(false);
  let history = useHistory()

  useEffect(() => {
    setDescription(row.description)
  }, [])
  

  const boxstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    height:'inherit',
    bgcolor: 'background.paper',
    border: '1px solid #bbb',
    boxShadow: 24,
    p: 4,
  };

  const handleUpdate = async () => {
    setLoadDescUpdate(true)

    let data = {
      id:row.product_id,
      field:'description',
      value:description
    }

    console.log('Description Component=>',row,data)
    try {
      let token = JSON.parse(authCode()).accessToken
      console.log(data)
      await axios.post(process.env.REACT_APP_EXTRAMILE_URL + '/admin/products/update', data,
      {
        headers: {
          'content-type': 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      })
      setLoadDescUpdate(false)
      history.go(0)
    } catch (error) {
      return setLoadDescUpdate(false)
    }
  };

  return (

    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      height="100%"
      px={1}
      py={1}
    >
      <Box>
        <p>{row.description}</p>
      </Box>
      <Button variant="outlined" color="primary" onClick={()=>setDescModalOpen(true)}>
        Update
      </Button>
      <Modal open={openDescModal} onClose={()=>setDescModalOpen(false)}   sx={{ '& .MuiBackdrop-root': { backgroundColor: 'transparent' } }}>
          <Box sx={boxstyle}>
          <TextField
                  multiline
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  style={{ marginTop: 0, marginBottom: 20 }}
                  value={description}
                  defaultValue=""
                  onChange={(e) => setDescription(e.target.value)}
          />
            {loadDescUpdate ? <LoadingButton />
            :
            <><Button color="primary" variant="outlined" onClick={()=>setDescModalOpen(false)} style={{ marginTop: '10px' }}>
              Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={handleUpdate} style={{ marginTop: '10px', marginLeft: '10px' }}>
              Update
          </Button>
          </>}
        </Box>
      </Modal>
    </Box>
)};

export default function ExtProductsTable() {
  const [products, setProducts] = useState([])
  const [load, setLoad] = useState(false)
  const [pageSize, setPageSize] = useState(10);
  const [error, setError] = useState(null);
  const rowHeight = 150;
  const headerClassName = 'wrap-header';
  const [showGrid, setShowGrid] = useState(true);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);



  const columns = (fetchProducts,toggleLoading) => [
    { field:'picture_url', headerName:'Product Image', width: 180, renderCell: (params)=>(
        <React.Fragment>
          <CloudImageUpload source={params.formattedValue} productId={params.row.product_id} onUpload={fetchProducts} loadingStatus={toggleLoading}/>
        </React.Fragment>
      ), editable:false },
    { field:'product_id', headerName:'Product ID', width: 80, editable:false, hide:true },
    { field: 'product_name', headerName: 'Product Name', width: 200, editable: true, cellClassName: 'wrap-cell',
    renderCell: (params) => (
      <React.Fragment>
        <Tooltip title={params.value}>
          <div>{params.value}</div>
        </Tooltip>
      </React.Fragment>
    )},
    { field:'description', headerName:'Description', width: 400, editable: false, cellClassName: 'wrap-cell',
    renderCell: (params) => (
      <React.Fragment>
        <DescriptionComponent row={params.row}></DescriptionComponent>
      </React.Fragment>
    )},
    { field:'limited_qty', headerName:'Limited Quantity Amount',type:'number', width: 100, editable: true, align:'center'},
    { field:'is_limited_qty', headerName:'Is Limited Quantity?',type:'singleSelect', width: 100, editable: true,valueOptions: ["true","false"],  align:'center'},
    { field: 'pts_value', headerName: 'Points Value', type: 'number', editable: true ,  align:'center'},
    { field: 'vendor', headerName: 'Vendor', width: 180, editable: true ,  align:'center'},
    { field: 'dollar_value', headerName: 'Cost(DollarValue)', width: 180, editable: true, ...usdPrice ,  align:'center'},
    { field: 'size', headerName: 'Size', width: 180, editable: true, valueFormatter: params => params.value === null ? '' : params.value,  align:'center' },
    { field: 'color', headerName: 'Color', width: 180, editable: true,  align:'center' },
    { field: 'add_options', headerName: 'Additional Options', width: 180, editable: true },
    { field: 'u_item_number', headerName: 'Commodity Code', width: 180, editable: true,  align:'center', hide:true },
    { field: 'v_item_number', headerName: 'Vendors Item No.', width: 180, editable: true ,  align:'center'},
    { field: 'status', headerName: 'Product Status',type:'boolean', width: 180, editable: true, align:'center',
      renderCell: params => params.value ? <CheckIcon  sx={{ backgroundColor: 'none', color: 'success.main' }} /> : <CloseIcon color="error" />
    },
    { field: 'created_date', headerName: 'Created Date', width: 180, editable: false, valueFormatter: (params) => moment(params.value).format('MMM-DD-YYYY')},
    { field: 'created_by', headerName: 'Created By', width: 180, editable: false, hide:true },
    { field: 'modified_date', headerName: 'Modified Date', width: 180, editable: false, hide:true },
    { field: 'modified_by', headerName: 'Modified By', width: 180, editable: false, hide:true },
    {
      field: 'actions',
      headerName: 'Delete ',
      width: 100,
      renderCell: (params) => (
        <Button style={{color: 'red'}}  onClick={() => { setOpenDeleteModal(true); setSelectedRow(params.row); }}>
          Delete
        </Button>
      ),
    }
  ];


  const toggleLoading = (status) => {
    setLoad(status)
  };

  const fetchProducts = async () => {
    try {

      let token = JSON.parse(authCode()).accessToken
      setLoad(true)
      const response = await axios.get(process.env.REACT_APP_EXTRAMILE_URL + '/admin/products', {
        headers: {
            'content-type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
      }).catch((error) => {
        setProducts([]);
        setError(error.message);
      });
      const data = response.data.products;
      setProducts(data);
      return setLoad(false)

    } catch (error) {
      setProducts([]);
      setError(error)
      return setLoad(false)
    }
  };

  const updateProducts = async (data) => {
    try {
      let token = JSON.parse(authCode()).accessToken
      console.log(data)
      await axios.post(process.env.REACT_APP_EXTRAMILE_URL + '/admin/products/update', data,
      {
        headers: {
          'content-type': 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      })
      return setLoad(false)
    } catch (error) {
      setError(error)
      return setLoad(false)
    }
  }

  const reloaddata = async () => {
    fetchProducts()
  }

  useEffect(() => {
    fetchProducts()
  }, []);

  const handleEditCellChangeCommitted = async (data) => {
    await updateProducts(data)
    return null
  };

  const handleApiError = () => {
    setShowGrid(false);
  };

  const handleDeleteTransaction = async (row) => {
    // Make API request to delete transaction
    let token = JSON.parse(authCode()).accessToken;
    console.log(row)
    await axios.delete(
      process.env.REACT_APP_EXTRAMILE_URL + '/admin/products/delete',
      {
        data: row,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      }
    );

    reloaddata()
    // Close the modal
    setOpenDeleteModal(false);
    // setOrders(orders.filter(orders => orders.redeem_id !== row.redeem_id))
  }


  return (
    <Container maxWidth={false}>
    <Typography variant="h6" gutterBottom component="div">Extramile Catalogue/Products</Typography>
    <br/>
    <Button size="small" onClick={reloaddata}>
      <RefreshIcon fontSize="medium" color='primary'/>
      Refresh Table
    </Button>
    <Collapse in={showGrid}>
    <div style={{ height: 'calc(100vh - 200px)', width: '100%' }}>
      <DataGrid
        loading={load}
        disableSelectionOnClick
        style={{ height: '80vh' }}
        forceUpdate
        pagination
        onError={handleApiError}
        density='comfortable'
        rowHeight={rowHeight}
        headerClassName={headerClassName}
        sx={{ overflowX: 'scroll' }}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20,50,100]}
        rows={products}
        getRowId={(row) => row?.product_id}
        columns={columns(fetchProducts, toggleLoading)}
        onCellEditCommit={handleEditCellChangeCommitted}
        components={{
          Toolbar: EditToolbar,
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              Error connecting. Please refresh the page or try again later.
            </Stack> )
        }} 
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 }
          },
        }}
      />
    </div>
    </Collapse>
    <DeleteConfirmationModal
      open={openDeleteModal}
      onClose={() => setOpenDeleteModal(false)}
      onDelete={handleDeleteTransaction}
      row={selectedRow}
    />
  </Container>
  );
}

function DeleteConfirmationModal({ open, onClose, onDelete, row }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm Delete</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Alert severity="error">Are you sure you want to delete this product? This action cannot be undone!</Alert>
          {row && 
          <Table>
            <TableBody>
              {Object.entries(row).map(([key, value]) => 
                <TableRow key={key}>
                  <TableCell component="th" scope="row">
                    {key}
                  </TableCell>
                  <TableCell align="left">
                    <strong>{value}</strong>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => onDelete(row)} style={{color: 'red'}} >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}