import React, { useEffect } from 'react'
import {useHistory} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import { isLogin, authCode } from '../utils/auth';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';


/* AUTH ROUTES */
import { Login, GetToken } from '../containers/Auth/authbutton'
import { authadminlogin } from '../actions';


export default function Home() {
    let history = useHistory();
    let dispatch = useDispatch();
    useEffect(() => {
        if(history.location.search !== '') {
            console.log('locsearch=>',history.location.search)
            let token = async () => {
                    await GetToken()
                    dispatch(authadminlogin(authCode()))
                    return isLogin ? history.push('/dashboard') : null
            }
            token()
        }
      },[dispatch, history]);
    

    return (
        <div className="home-ct">
            <Container>      
            <Typography
                color="inherit"
                align="center"
                variant="h5"
                sx={{ mb: 4, mt: { sx: 4, sm: 10 } }}
            >
                 Utah Department Of Transport
            </Typography>
            <Typography
                color="inherit"
                align="center"
                variant="body2"
            >
                 This application is used by adminstrators of Interchange applications. <br/>
                 To access individual apps, please use the application links below. 
            </Typography>
            <br/>
            <Typography variant="body2" color="inherit" sx={{ mt: 2 }} align="center">
                <Login />
            </Typography>
            <br/>
            <Typography variant="body2" color="inherit" sx={{ mt: 2 }} align="center">
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={2} sm={4} md={4} > <a href="https://gasb.udot.utah.gov" target="_blank" rel="noreferrer">GASB</a></Grid>
                <Grid item xs={2} sm={4} md={4} > <a href={process.env.REACT_APP_EXTRAMILE} target="_blank" rel="noreferrer">EXTRAMILE</a></Grid>
                <Grid item xs={2} sm={4} md={4} > CONSULTANT EVALUATION FORM</Grid>
                <Grid item xs={2} sm={4} md={4} > FINANCE APPROVALS</Grid>
            </Grid>
            </Typography>

            </Container>
        </div>
    )
}