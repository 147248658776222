import Cookies from 'js-cookie'
import {LOGIN_REQUEST ,LOGIN_SUCCESS, LOGOUT} from '../actions';

export default function auth(state = {
    isAuthenticated: false,
    isFetching: false,
    profile:{},
    error: null
  }, action) {
        switch (action.type) {
            case LOGIN_REQUEST:
                return { ...state, isFetching: true}
            case LOGIN_SUCCESS:
                console.log('8cR:',action.payload)
                // localStorage.setItem('_AUTHSTAT',action.payload.data.profile.admin_status)
                Cookies.set('_AUTHSTAT', action.payload.data.profile.admin_status, { expires: 0.2})
                console.log('8cS:',action.payload.data)
                if(action.payload.data.profile.admin_status === true){
                    return {...state, profile:action.payload.data.profile.user, isFetching: false, isAuthenticated:true}
                } else if(action.payload.data.admin_status === false){
                    return {...state, error:action.payload.error, isFetching: false, isAuthenticated:false}
                }
                else return {...state, isFetching:false};
            case LOGOUT:
                return {...state, isAuthenticated:false}
            default:
                return {...state}
        }
  }