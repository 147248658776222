import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import _ from 'lodash';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { mainListItems,extramileListItems, secondaryListItems, gasbListItems } from './listitems';
import { Logout } from '../Auth/authbutton';
import { ListItem } from '@mui/material';


const drawerWidth = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const mdTheme = createTheme();

export default function DashboardComponent({ children }) {
  const [open, setOpen] = useState(true);
  const {profile} = useSelector(state => state.auth)
  const [apps, setApps] = useState([])
  console.log('APPS=>',apps)

  useEffect(() => {
    if (_.isEmpty(profile)){
      return null
    }
    else if(profile.superadmin.app.length > 0){
      setApps([...profile.superadmin.app])
    }
    else return null
  }, [profile])
  

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            {_.includes(apps,'extramile', 0) ? extramileListItems : <div/>}
            {_.includes(apps,'gasb', 0) ? gasbListItems : <div/>}
            <Divider sx={{ my: 1 }} />
            { _.includes(apps,'interchangeadmin', 0) ? secondaryListItems : <div/> }
            <ListItem>
              <Logout />
            </ListItem>
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[50]
                : theme.palette.grey[900],
            flexGrow: 1
          }}
        >
          <Toolbar />
          {/* <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}> */}
            <Grid container spacing={1}>
              {/* Body */}
              { children }
            </Grid>
          {/* </Container> */}
        </Box>
      </Box>
    </ThemeProvider>
  );
}

