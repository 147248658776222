import React, {useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import {useSelector} from 'react-redux'
import DashboardComponent from '../../containers/Dashboard/component';
import {ExtRegionsTable} from '../../containers/Tables';

export default function ExtRegions() {
        
        let history = useHistory();
        const {auth} = useSelector((state)=> state)
        
        useEffect(() => {
            setTimeout(()=> {
                if(auth.isAuthenticated){
                    return;
                }
                else {
                    return () => history.push('/')
                }
            },2500);
          }, [auth, history]);

        return (
            <div>
                    <DashboardComponent><ExtRegionsTable/></DashboardComponent>
            </div>  
        )
}