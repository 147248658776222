import configureStore from '../store';
import Cookies from 'js-cookie'

const TOKEN_KEY = 'oauth2authcodepkce-state';
const AUTH_STAT = '_AUTHSTAT'

const time = Math.floor(Date.now() / 1000);
const { persistor } = configureStore();



export const login = (accessToken) => {
    localStorage.setItem(TOKEN_KEY, `${accessToken}`);
}

export const logout = () => {
    localStorage.removeItem(TOKEN_KEY);
    Cookies.remove(AUTH_STAT)
    persistor.flush().then(() => { return persistor.purge() })
    localStorage.removeItem('persist:root');
}

export const isAdminAuth = () => {
    if (Cookies.get(AUTH_STAT)) {
        console.log('Cookie=>',Cookies.get(AUTH_STAT))
        let x = Cookies.get(AUTH_STAT) === "true" ? true : false
        return x
    }
    else {
        return false
    }
}

export const isLogin = () => {
    let authToken = JSON.parse(localStorage.getItem(TOKEN_KEY,JSON.stringify(TOKEN_KEY)))
    if (authToken) {
        let expiry = Math.floor(new Date(authToken.accessTokenExpiry) / 1000)
        if (expiry >= time) {
            return true;
        }
        else if (expiry < time) {
            logout()
            return false;
        }
        else {return false}
    }
    else if(!authToken || authToken === 'undefined'){
        return false
    }
}



export const authCode = () => {
    return localStorage.getItem(TOKEN_KEY);
}