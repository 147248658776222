import { useState, useEffect } from 'react';
import axios from 'axios'
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  TextField,
  Button,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import { authCode } from '../../utils/auth';
import GetColleagueInfo from '../../components/autocomplete';

/*
    TODO: Handle API call to extramile admin form to create the transaction
    ! See what acct_id needs to be passed to the form, or if system acct_id can be used
*/

const AddTxForm = ({ open, handleClose, handleAddUser }) => {
  
    const { register, handleSubmit, reset, watch, control } = useForm();
    const [submitting, setSubmitting] = useState(false);
    let token = JSON.parse(authCode()).accessToken
    const [regions, setRegions] = useState([]); 
    const {auth} = useSelector((state)=> state)

    useEffect(() => {
        let getregions = async () => {
            let token = JSON.parse(authCode()).accessToken
            axios.get(`${process.env.REACT_APP_EXTRAMILE_URL}/regions`, {
              headers: {
                  'Authorization': `Bearer ${token}`
              }
            }).then((res) => {
              setRegions(res.data.regions);
            })
        }
        getregions();
    },[])

    const onSubmit = async (data) => {
        setSubmitting(true);

    try {
            let token = JSON.parse(authCode()).accessToken
            let idToken = JSON.parse(authCode()).idToken
            let request  = await axios.get(`${process.env.REACT_APP_EXTRAMILE_URL}/user/object`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Token':`${idToken}`
                }
            })

            let acctId = request.data.data.acct_id

            let formdata = [{
                "acct_id": acctId, 
                "receiver": {
                    "name":`${data.to.first_name} ${data.to.last_name}`,
                    "email":`${data.to.work_email}`
                },
                "giver": {
                    "name":`${data.from.first_name} ${data.from.last_name}`,
                    "email":`${data.from.work_email}`
                }, 
                "core_value": data.corevalue, 
                "amount": data.amount, 
                "giver_group": data.giverregion, 
                "receiver_group": data.receiverregion, 
                "comments": data.comment,
                "admin_notes": data.admin_notes,
                "created_by": auth.profile.name.fullname
            }]

            console.log('FORM DATA',formdata)
            setTimeout(() => {
                setSubmitting(false);
            }, 2000);
        
        const response = await axios.post(`${process.env.REACT_APP_EXTRAMILE_URL}/form/admin/givepoints`, formdata, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Token':`${idToken}`
            }
        })    
        .catch(error => console.log(error));
        console.log(response);
    
        if (response.status === 200) {
            reset();
            setSubmitting(false);
            handleClose();
            window.location.href = '/extramile/transactions';
          } else {
            console.log(response.data);
          }

      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }

    };

    const values = watch();

    return (
        <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>Employee Appreciation</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Admin - Give Points 
                    <br/>
                    <br/>
                    Admins can use this form to give points to other colleagues. The points will be credited to the colleague who is selected in the form.
                </DialogContentText>
                <br/>
                <InputLabel htmlFor="bootstrap-input">
                    Select a colleague to give points <strong>FROM</strong>
                </InputLabel>
                <GetColleagueInfo name={"from"} control={control}/>
                <br/>
                <InputLabel htmlFor="bootstrap-input">
                        Select a colleague to give points <strong>TO</strong>
                </InputLabel>
                <GetColleagueInfo name={"to"} control={control}/>
                <br/>

                <Controller
                    name={"amount"}
                    control={control}
                    defaultValue={1}
                    render={({ field: { onChange, value } }) => (
                        <>
                            <InputLabel htmlFor="bootstrap-input">
                                Points
                            </InputLabel>
                            <Select
                                fullWidth={true}
                                labelId="amount"
                                id="amount"
                                label="amount"
                                style={{ marginTop: 0,marginBottom: 20 }}
                                value={value}
                                onChange={onChange}
                            >
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                            </Select>
                        </>
                    )}
                />

                <Controller
                    name={"corevalue"}
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                        <>
                            <InputLabel htmlFor="bootstrap-input">
                                Pick a core value / emphasis area
                            </InputLabel>
                            <Select
                                fullWidth={true}
                                labelId="corevalue"
                                id="corevalue"
                                label="Age"
                                style={{ marginTop: 0,marginBottom: 20 }}
                                value={value}
                                onChange={onChange}
                            >
                                <MenuItem value={'Collaboration'}>Collaboration</MenuItem>
                                <MenuItem value={'Dedication'}>Dedication</MenuItem>
                                <MenuItem value={'Education'}>Education</MenuItem>
                                <MenuItem value={'Fiscal Responsibility'}>Fiscal Responsibility</MenuItem>
                                <MenuItem value={'Integrated Transportation'}>Integrated Transportation</MenuItem>
                                <MenuItem value={'Integrity'}>Integrity</MenuItem>
                                <MenuItem value={'N/A'}>N/A</MenuItem>
                                <MenuItem value={'Passion'}>Passion</MenuItem>
                                <MenuItem value={'Quality'}>Quality </MenuItem>
                                <MenuItem value={'Safety'}>Safety</MenuItem>
                                <MenuItem value={'Transparency'}>Transparency</MenuItem>
                                <MenuItem value={'Trust'}>Trust</MenuItem>
                            </Select>
                        </>
                    )}
                />

                <Controller
                    name={"giverregion"}
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                        <>
                            <InputLabel htmlFor="bootstrap-input">
                                Giver Group
                            </InputLabel>
                            <Select
                                fullWidth={true}
                                labelId="giverregion"
                                id="giverregion"
                                label="Giver Region"
                                style={{ marginTop: 0,marginBottom: 20 }}
                                value={value}
                                onChange={onChange}
                            >
                                {regions.map(e => {
                                    return (
                                        <MenuItem key={e} value={e}>
                                            {e}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </>
                    )}
                />

                <Controller
                    name={"receiverregion"}
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                        <>
                            <InputLabel htmlFor="bootstrap-input">
                                Receiver Group
                            </InputLabel>
                            <Select
                                fullWidth={true}
                                labelId="receiverregion"
                                id="receiverregion"
                                label="Receiver Region"
                                style={{ marginTop: 0,marginBottom: 20 }}
                                value={value}
                                onChange={onChange}
                            >
                                {regions.map(e => {
                                    return (
                                        <MenuItem key={e} value={e}>
                                            {e}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </>
                    )}
                />

                <Controller
                    name={"comment"}
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                        <>
                            <InputLabel htmlFor="bootstrap-input">
                                Leave A Comment
                            </InputLabel>
                            <TextField
                                id="outlined-textarea"
                                style={{ marginTop: 0,marginBottom: 20 }}
                                multiline
                                fullWidth={true}
                                rows={3}
                                placeholder="Limited to 1500 characters..."
                                value={value}
                                onChange={onChange}
                            />
                        </>
                    )}
                />

                <Controller
                    name={"admin_notes"}
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                        <>
                            <InputLabel htmlFor="bootstrap-input">
                                Admin Notes(For admin purposes only)
                            </InputLabel>
                            <TextField
                                id="outlined-textarea"
                                style={{ marginTop: 0,marginBottom: 20 }}
                                multiline
                                fullWidth={true}
                                rows={3}
                                placeholder="Limited to 1500 characters..."
                                value={value}
                                onChange={onChange}
                            />
                        </>
                    )}
                />

            </DialogContent>
            <DialogActions>
                { submitting ? null : <Button onClick={handleClose} sx={{color:'#9b9b9b'}}>Cancel</Button> }
                <LoadingButton loading={submitting}  type="submit" loadingIndicator="Creating Transaction ..." endIcon={<SendIcon />}>Submit</LoadingButton>
            </DialogActions>
        </form>
        </Dialog>
    );
};

export default AddTxForm;
