import { useState } from 'react';
import axios from 'axios'
import { useForm } from 'react-hook-form';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormHelperText ,
  Button,
  Box,
  Typography
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import { authCode } from '../../utils/auth';

const AddUserForm = ({ open, handleClose, handleAddUser }) => {
  
    const { register, handleSubmit, reset, watch } = useForm();
    const [submitting, setSubmitting] = useState(false);
    let token = JSON.parse(authCode()).accessToken

    const onSubmit = async (data) => {

    setSubmitting(true);
    const newUser = {
        workforceID: data.workforceId,
        given_name: data.firstname,
        family_name: data.lastname,
        full_name: data.fullname,
        email: data.email,
        description: data.description
    };

    try {
        
        const response = await axios.post(process.env.REACT_APP_ROOT_URL + '/user/create', newUser,
        {
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        });
        console.log(response.data);
    
        if (response.status === 200) {
            reset();
            setSubmitting(false);
            handleClose();
            window.location.href = '/reports/users';
          } else {
            console.log(response.data);
          }

      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }

    };

    const values = watch();

    return (
        <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>Add A New User</DialogTitle>
            <DialogContent>
                
                <TextField
                    autoFocus
                    margin="dense"
                    label="Workforce ID"
                    defaultValue={0}
                    sx={{ mb: 2 }}
                    helperText={<FormHelperText sx={{ color: 'red' }}>{'Leave as default 0 if you do not know the user\'s Workforce ID'}</FormHelperText>}
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    {...register('workforceId', { required: true })}
                    fullWidth
                />

                <TextField
                    margin="dense"
                    label="Full Name"
                    type="string"
                    {...register('fullname', { required: true })}
                    fullWidth
                />
                
                <TextField
                    margin="dense"
                    label="First Name"
                    type="string"
                    {...register('firstname')}
                    fullWidth
                />

                <TextField
                    margin="dense"
                    label="Last Name"
                    type="string"
                    {...register('lastname')}
                    sx={{ mb: 2 }}
                    fullWidth
                />

                <TextField
                    margin="dense"
                    label="Email"
                    type="email"
                    {...register('email')}
                    fullWidth
                />

                <TextField
                    margin="dense"
                    label="Description"
                    type="desciption"
                    defaultValue={''}
                    sx={{ mb: 2 }}
                    {...register('description')}
                    fullWidth
                />
                <Box sx={{ mt: 2 }}>
                    <Typography  sx={{ fontSize: "0.875rem", color: "text.secondary" }}>Confirmation:</Typography>
                    <Box sx={{ ml: 2, fontSize: "0.875rem" }}>
                        <Typography sx={{ fontSize: "0.875rem", color: "text.secondary" }}>
                            Workforce ID: {values.workforceId }<br />
                            Full Name {values.fullname}<br />
                            First Name: {values.firstname || "-"}<br />
                            Last Name: {values.lastname || "-"}<br />
                            Email: {values.email || "-"}<br />
                            {/* display other form fields */}
                        </Typography>
                    </Box>
                </Box>

            </DialogContent>
            <DialogActions>
            { submitting ? null : <Button onClick={handleClose} sx={{color:'#9b9b9b'}}>Cancel</Button> }
            <LoadingButton loading={submitting}  type="submit" loadingIndicator="Adding User ..." endIcon={<SendIcon />}>Add</LoadingButton>
            </DialogActions>
        </form>
        </Dialog>
    );
};

export default AddUserForm;
