import {createStore, applyMiddleware, compose} from 'redux';
import rootReducer from './reducers/root_reducer';
import thunk from 'redux-thunk';

import { persistStore, persistReducer } from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'

const persistConfig = {
  key: 'root',
  storage:storageSession,
  stateReconciler: hardSet
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistedReducer = persistReducer(persistConfig, rootReducer)
const AppState = (initialState) => {
  let store = createStore(persistedReducer, initialState, composeEnhancers(applyMiddleware(thunk)))
  let persistor = persistStore(store)
  return { store, persistor }
}

export default AppState
