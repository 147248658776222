import React, { useEffect, useState } from 'react';
import _ from 'lodash'
import axios from 'axios';
import moment from 'moment'
import { useHistory } from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux'
import { authCode } from '../../../utils/auth';
import { Container,Typography, Button, Collapse } from '@mui/material';
import {Dialog,DialogActions,DialogContent, DialogTitle} from '@mui/material';
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import SaveIcon from '@mui/icons-material/Save';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { DataGrid, GridAddIcon, GridToolbarContainer,GridToolbarColumnsButton, GridToolbarFilterButton,GridToolbarDensitySelector, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { extramileaccounts, ext_updateaccounts,getallusers, ext_acctdupcheck,ext_createacct, ext_calctotal } from '../../../actions';
import { Table, TableBody, TableCell, TableRow, DialogContentText } from '@mui/material';
import { Delete } from '@mui/icons-material';



/*
  ? How are an accounts total points calculated
  * Total Points = Total Received Points - Total Shared Points - Total Redeemed Points
  ? How are giveable points calculated
  * 4(per fiscal year) - any amount with TX Type Give(for the current fiscal year)
  ? Can a person have more than 4 giveable points?
  * Yes
  ? Should Account ID & Workforce ID be editable?
  * Preferably No - should a person require a new AccountID or is assigned a new WorkforceID, create a new user account and extramile account for the user, then update the new account and soft delete the old account
  ? Who will require new accounts?
  * Typically people not associated with UDOT
  ? Are superadmins required to edit any info when creating a new user account?
  * No. In order to create a new account, a superadmin can ONLY create a new account if the user exists.
  ? What is the process for creating a new account?
  * Click + create account > search for user from InterchangeAdmin Users > Click on the selected user(if user does not exist, prompt user to create a user account) > When user clicks 'Add Account' validate for duplicate(WorkforceID + Email), and if duplicate display duplicate message, if not a duplicate proceed to create and call create account API\
  ? What account fields are editable?
  * fullname, email, voucher admin, employeeadmin, giveable,modified date, acct deleted
  ? What field requires recalculation feature
  * Total Points & Redeemable. Recalculate both fields when recalculate button is clicked. Recalculation should be on a per account basis
  ? What format should the dates be in?
  * MMM DD YYYY as requested by Amber
  ? What are voucher admins?
  * Voucher Admins are senior leadership stafff able to give/share 2 points compared to normal staff only able to give/share 1 points
  ? What are employee admins?
  * Employee admins are the contact person within the regions
  ? Is redeemable field still used
  ! Redeemable field is no longer used under ext_accounts. Calculate users redeemables from ext_orders table
*/
/*
  TODO: Bug with duplicate check
*/

function EditToolbar() {
  const [openAddUser, setOpenAddUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const usersData = useSelector((state)=> state.users.data)
  const options = _.values(usersData)
  const [alertOpen, setAlertOpen] = useState(false);


  const showAlert = () => {
    setAlertOpen(true);
    setTimeout(() => {
      closeAlert();
    }, 2500);
  };

  const closeAlert = () => {
    setAlertOpen(false);
  };

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 25, // show only 25 options at a time
  });
  
  const dispatch = useDispatch()
  const history = useHistory()

  const addUser = () => {
    setOpenAddUser(true);
    dispatch(getallusers())
  }

  const handleClose = () => {
    setOpenAddUser(false);
    setAlertOpen(false);
  };

  const createUserAcct = () => {
    setLoading(true);
    console.log('ADDING ACC FOR:', inputValue)
    dispatch(ext_acctdupcheck(inputValue))
    .then((response) => {
      let status = response.payload.data
      console.log('STAT',status, typeof status.acct_dup_check)
      if(status.acct_dup_check) {
        console.log('RES=>STATUS TRUE');
        setLoading(false)
        showAlert()
      }
      else if(!status.acct_dup_check) {
        dispatch(ext_createacct(inputValue))
        setTimeout(() => {
          setLoading(false)
          return history.go()
        }, 2500);
      }
    })
    .catch(function (error) {
      setLoading(false)
      console.log(error);
    })
  };

  return (
    <GridToolbarContainer>
      <GridToolbarQuickFilter />
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <Button color="primary" startIcon={<GridAddIcon />} onClick={addUser}>
        Create Account
      </Button>

    <Dialog open={openAddUser} onClose={handleClose}>
    <DialogTitle>Create Extramile Account For</DialogTitle>
    <DialogContent>

    <Autocomplete
      freeSolo
      onChange={(event, value) => {
        console.log(value)
        setInputValue(value)
      }}
      sx={{ "& .MuiAutocomplete-option": { fontSize: 10 }, width: '500px', height:'200px'}}
      variant="outlined"
      id="usersacct"
      filterOptions={filterOptions}
      options={options}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={(option) => {
        console.log(option);
        if (option && option.name && option.name.fullname) {
          return ` WorkforceID:${option.workforceID}\n Name:${option.name.fullname}\n Email: ${option.email} `;
        } else {
          return '';
        }
      }}
      loading={loading}
      renderInput={(params) => (
        <React.Fragment>
          <TextField
            {...params}
            multiline
            margin="normal"
            label="Type a person's name to search"
            helperText="If you are unable to find the user, request user to attempt to login to the extramile app first."
            InputProps={{
              ...params.InputProps,
              type: 'search',
              style: { fontSize: 15 }
            }}
            InputLabelProps={{style: {fontSize: 15}}} 
          />
          
        </React.Fragment>
      )}
    />
    { alertOpen ? <Alert severity="error" onClose={closeAlert} open={alertOpen}>Duplicate account exists! Create a new user if absolutely necessary then create a new account</Alert> : null }
    </DialogContent>
    <DialogActions>
      {loading ? null : <Button onClick={handleClose} sx={{color:'#9b9b9b'}}>Cancel</Button>}
      <LoadingButton loading={loading} onClick={createUserAcct} loadingIndicator="Adding Account ..." endIcon={<SaveIcon />}>Add Account</LoadingButton>
    </DialogActions>
    </Dialog>
    </GridToolbarContainer>
  );
}

function CustomRenderCell({row}) {
  const dispatch = useDispatch();
  const history = useHistory()
  const {isFetching} = useSelector(state => state.extaccounts);
  console.log(row)
  const handleRecalculateTotal = async () => {
    dispatch(ext_calctotal(row.acct_id));
    setTimeout(() => {
      history.go()
    }, 2500);
  };

  return (
    <>
    <Button
      variant='outlined'
      color="primary"
      disabled={isFetching === true}
      onClick={handleRecalculateTotal}
    >
      {isFetching === true ? 'Calculating...' : 'Recalculate Total'}
    </Button>
    </>
  );
}




export default function ExtAccountsTable() {

  const [pageSize, setPageSize] = useState(10);
  const [accounts, setAccounts] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch()
  const [showGrid, setShowGrid] = useState(true);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const columns = [
    { field: 'workforce_id', headerName: 'Workforce ID', width: 180, editable: true },
    { field: 'acct_id', headerName: 'Account ID', width: 180, editable: true },
    { field: 'fullname', headerName: 'FullName', width: 180, editable: true },
    { field: 'email', headerName: 'Email', width: 200, editable: true },
    { field: 'is_voucher_admin', headerName: 'Voucher Admin', type:'singleSelect', width: 100, editable: true,valueOptions: ["true","false"]  },
    { field: 'is_employee_admin', headerName: 'Employee Admin', type:'singleSelect', width: 100, editable: true,valueOptions: ["true","false"] },
    { field: 'balance', headerName: 'Total Points',  width: 100, editable: false },
    { field: 'giveable', headerName: 'Giveable',  width: 100, editable: true },
    { field: 'redeemed', headerName: 'Redeemed', width: 100, editable: false },
    { field: 'created_date', headerName: 'Created Date',valueGetter: (params) => moment(params.value).format('MMM-DD-YYYY'), width: 180, editable: false },
    { field: 'modified_date', headerName: 'Modified Date',valueGetter: (params) => moment(params.value).format('MMM-DD-YYYY'), width: 180, editable: false, hide: true },
    { field: 'merged_acct', headerName: 'Merged Account',  width: 100, editable: false, hide:true },
    { field: 'soft_delete', headerName: 'Acct Deleted', width: 100, editable: true, hide:true },
    {
      field: "action",
      headerName: "Action",
      width: 350,
      sortable: false,
      renderCell: (params) => (
      <>
        <CustomRenderCell row={params.row}/>
        <Button style={{color: 'red', marginLeft:'20px'}}  startIcon={<Delete />} onClick={() => { setOpenDeleteModal(true); setSelectedRow(params.row); }}>
          Delete
        </Button>
      </>
      )
    },
  ];

  const reloaddata = async () => {
    setIsLoading(true)
    dispatch(extramileaccounts())
    .then((data) => {
      setAccounts(data.payload.data);
      setIsLoading(false);
    })
    .catch((error) => {
      setError(error);
      setIsLoading(false);
    });
  }

  useEffect(() => {
    setIsLoading(true);
    dispatch(extramileaccounts())
      .then((data) => {
        setAccounts(data.payload.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, [dispatch]);


  const handleCellEditCommit = async (params) => {

    console.log('edited data => ',params)
    dispatch(ext_updateaccounts(params));
    return
  };

  const handleApiError = () => {
    setShowGrid(false);
  };

  return (
      <Container maxWidth={false}>
        <Typography variant="h6" gutterBottom component="div">Extramile Accounts</Typography>
        <br/>
        <Button size="small" onClick={reloaddata}>
          <RefreshIcon fontSize="medium" color='primary'/>
          Refresh Table
        </Button>
        <Collapse in={showGrid}>
        <div style={{ height: 'calc(100vh - 200px)', width: '100%' }}>
        <DataGrid
          loading={isLoading}
          onError={handleApiError}
          forceUpdate
          density='comfortable'
          sx={{ overflowX: 'scroll' }}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 20,50,100]} 
          rows={accounts}
          getRowId={(row) => row?.acct_id}  
          columns={columns}
          getActionsProps={(params) => ({ dispatch })} 
          onCellEditCommit ={(params) => handleCellEditCommit(params)}
          components={{
            Toolbar: EditToolbar,
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                Error connecting. Please refresh the page or try again later.
              </Stack> )
          }} 
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
        </div>
        </Collapse>
        <DeleteConfirmationModal
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          row={selectedRow}
        />
      </Container>
  );
}

async function checkRecords(acct_id) {
  const token = JSON.parse(authCode()).accessToken;
  
  const apiEndpoints = [
    '/pointsearned/',
    '/pointsgiven/',
    '/orders/'
  ];
  
  const records = {};
  
  for (let endpoint of apiEndpoints) {
    try {
      const response = await axios.get(`${process.env.REACT_APP_EXTRAMILE_URL}${endpoint}${acct_id}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
      });

      if (Array.isArray(response.data.data) && response.data.data.length > 0) {
        records[endpoint] = response.data.data.length;
      }
    } catch (error) {
      console.error(`Error fetching data from ${endpoint}: `, error);
    }
  }

  return records;
}


function DeleteConfirmationModal({ open, onClose, row }) {
  let history = useHistory()

  const [records, setRecords] = React.useState({
    '/pointsearned/': 0,
    '/pointsgiven/': 0,
    '/orders/': 0,
  });

  React.useEffect(() => {
    async function fetchData() {
      const fetchedRecords = await checkRecords(row.acct_id);
      setRecords(fetchedRecords);
    }

    if (open) {
      fetchData();
    }
  }, []);

  const endpointLabels = {
    '/pointsearned/': 'Points Earned Transactions',
    '/pointsgiven/': 'Points Given Transactions',
    '/orders/': 'Order Transactions',
  };

  const handleDelete = async () => {
    const token = JSON.parse(authCode()).accessToken;
    try {
      const response = await axios.delete(`${process.env.REACT_APP_EXTRAMILE_URL}/admin/accounts/delete`,{
        data:row, 
        headers: {
            'Authorization': `Bearer ${token}`,
        }
      });
      console.log(response.data);
      onClose();
      history.go()
    } catch (error) {
      console.log(error);
      // Maybe show some kind of error message here
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm Delete</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Alert severity="error">Do not delete account UNLESS absolutely necessary? This action cannot be undone!</Alert>
          <br/>
          <Alert severity="warning">There could be transactions or orders associated with this account. Please check to migrate those over to the new account before performing this action! Scroll to the bottom and verify that the transactions are 0 records/No transactions before proceeding</Alert>
          {row && 
          <Table>
            <TableBody>
              {Object.entries(row).map(([key, value]) => 
                <TableRow key={key}>
                  <TableCell component="th" scope="row">
                    {key}
                  </TableCell>
                  <TableCell align="left">
                    <strong>{value}</strong>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          }
          {Object.keys(endpointLabels).map((key) =>
            <div key={key}>{endpointLabels[key]}: {records[key] > 0 ? `${records[key]} records` : 'No transactions'}</div>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleDelete} style={{color: 'red'}} >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
