import React, {useState, useEffect } from 'react';
import { authCode } from '../utils/auth';
import axios from 'axios'
import { Controller } from "react-hook-form";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

export default function GetColleagueInfo({ onChange: ignored,control, name } ) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;
  const [names] = useState([]);

  async function nameAPI(data) {

    let token = JSON.parse(authCode()).accessToken
    let idToken = JSON.parse(authCode()).idToken
    let employeeName = data.toLowerCase();

    let getEmployeeDetails = await axios.get(`${process.env.REACT_APP_EXTRAMILE_URL}/user/getcolleague?name=${employeeName}`, {
                                headers: {
                                  'Authorization': `Bearer ${token}`,
                                  'Token':`${idToken}`
                                }
                              });

    if (getEmployeeDetails.data.data.length >= 1) {
      setOptions(getEmployeeDetails.data.data);
    }
    else if (getEmployeeDetails.data.status === 500) {
      setOptions([]);
    }
  }

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      if (active) {
        setOptions([...names]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading,names]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Controller
        render={({field})=> (
            <Autocomplete
                fullWidth={true}
                variant="outlined"
                open={open}
                onOpen={() => {
                setOpen(true);
                }}
                onClose={() => {
                setOpen(false);
                }}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                getOptionLabel={(option) => `${option.first_name} ${option.last_name} [${option.work_email}]`}
                options={options}
                loading={loading}
                onChange={(event,value) => {field.onChange(value)}}
                renderInput={(params) => (
                <TextField
                    {...params}
                    onChange={(e)=> e.target.value.length >= 3 ? nameAPI(e.target.value) : null }
                    InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                        </React.Fragment>
                    ),
                    }}
                />
                )}
            />
        )}
        onChange={([,data]) => data}
        defaultValue=""
        name={name}
        control={control}
   />
  );
}