import {EXT_PRODUCTS_REQUEST ,EXT_PRODUCTS_RESPONSE} from '../../actions';

export default function extproducts(state = {
    isFetching: false,
    message:'',
    data:[],
    error: null
  }, action) {
        switch (action.type) {
            case EXT_PRODUCTS_REQUEST:
                return { ...state, isFetching: true}
            case EXT_PRODUCTS_RESPONSE:
                if(action.payload.status === 200){
                    console.log(action.payload.data)
                    return {...state, isFetching: false, data:action.payload.data.data}
                } else {
                    return {...state, error:action.payload.error, status:'error', isFetching: false}
                }
            default:
                return state
        }
  }