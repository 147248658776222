import {EXT_ACCOUNTS_REQUEST ,EXT_ACCOUNTS_RESPONSE, EXT_CALC_TOTAL_REQ, EXT_CALC_TOTAL_RES} from '../../actions';

export default function extaccounts(state = {
    isFetching: false,
    message:'',
    data:[],
    error: null
  }, action) {
        switch (action.type) {
            case EXT_ACCOUNTS_REQUEST:
                return { ...state, isFetching: true}
            case EXT_ACCOUNTS_RESPONSE:

                if(action.payload.status === 200){
                    return {...state, isFetching: false, data:action.payload.data.data}
                } else {
                    return {...state, error:action.payload.error, status:'error', isFetching: false}
                }
            case EXT_CALC_TOTAL_REQ:
                return { ...state, isFetching: true}
            case EXT_CALC_TOTAL_RES:
                return { ...state, isFetching: false}
            default:
                return state
        }
  }