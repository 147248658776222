import {EXT_TRANSACTIONS_REQUEST ,EXT_TRANSACTIONS_RESPONSE} from '../../actions';

export default function exttransactions(state = {
    isFetching: false,
    message:'',
    data:[],
    error: null
  }, action) {
        switch (action.type) {
            case EXT_TRANSACTIONS_REQUEST:
                return { ...state, isFetching: true}
            case EXT_TRANSACTIONS_RESPONSE:
                if(action.payload.status === 200){
                    console.log(action.payload.data)
                    return {...state, isFetching: false, data:action.payload.data.data}
                } else {
                    return {...state, error:action.payload.error, status:'error', isFetching: false}
                }
            default:
                return {...state}
        }
  }