import React from 'react';
import './App.scss';
import CssBaseline from '@mui/material/CssBaseline';
import { Switch } from "react-router-dom"

import PrivateRoute from './containers/Auth/privateroute'
import PublicRoute  from './containers/Auth/publicroute'

import Header from './components/header';
import Dashboard from './views/Dashboard';
import Home from './views/Home';
import Users from './views/Users';
import Extramile from './views/Extramile';

import ExtAcct from './views/Extramile/Accounts';
import ExtOrders from './views/Extramile/Orders';
import ExtTransactions from './views/Extramile/Transactions';
import ExtProducts from './views/Extramile/Products';
import ExtRegions from './views/Extramile/Regions';
import EmployeeAdmin from './views/Extramile/EmployeeAdmin';


function App() {
  return (
    <div className="App">
      <CssBaseline />
      <Header />
      <Switch>
        <PublicRoute restricted={true} component={Home} path="/" exact />
        <PrivateRoute component={Dashboard} path="/dashboard" />
        <PrivateRoute component={Users} path="/reports/users" />
        <PrivateRoute component={Extramile} path="/reports/extramile" />
        <PrivateRoute component={ExtAcct} path="/extramile/accounts" />
        <PrivateRoute component={ExtOrders} path="/extramile/orders" />
        <PrivateRoute component={ExtProducts} path="/extramile/products" />
        <PrivateRoute component={ExtTransactions} path="/extramile/transactions" />
        <PrivateRoute component={ExtRegions} path="/extramile/regions" />
        <PrivateRoute component={EmployeeAdmin} path="/extramile/employeeadmin" />
      </Switch>
    </div>
  );
}

export default App;
