import {EXT_ACCOUNTS_CREATE_REQUEST ,EXT_ACCOUNTS_CREATE_RESPONSE} from '../../actions';

export default function extcreateaccounts(state = {
    isFetching: false,
    message:'',
    data:[],
    error: null
  }, action) {
        switch (action.type) {
            case EXT_ACCOUNTS_CREATE_REQUEST:
                return { ...state, isFetching: true}
            case EXT_ACCOUNTS_CREATE_RESPONSE:
                console.log('8cAcC:',action.payload)
                if(action.status === 200){
                    console.log(action.payload.data)
                    return {...state, isFetching: false, account:action.payload.data.data}
                } else {
                    return {...state, error:action.payload.error, status:'error', isFetching: false}
                }
            default:
                return state
        }
  }