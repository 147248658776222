import React from 'react';
import {
  TextField,
  FormHelperText,
  FormControlLabel,
  Switch,
  FormControl,
  InputLabel,
  InputAdornment,
  Select,
  MenuItem,
} from '@mui/material';
import { Controller } from 'react-hook-form';

const NewProductFields = ({ control }) => {
  return (
    <>
    
      <Controller
        name="product_name"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <>
          <InputLabel htmlFor="bootstrap-input">
            Product Name
          </InputLabel>
          <TextField
            variant="outlined"
            fullWidth
            margin="normal"
            style={{ marginTop: 0,marginBottom: 20}}
            {...field}
          />
          </>
        )}
      />

      <Controller
        name="description"
        control={control}
        defaultValue=""
        parse={(value) => value.substring(0, 1000)}
        render={({ field }) => (
          <>
          <InputLabel htmlFor="bootstrap-input">
          Description
          </InputLabel>
          <TextField
              multiline
              variant="outlined"
              fullWidth
              margin="normal"
              style={{ marginTop: 0, marginBottom: 20 }}
              maxLength={1500}
              helperText={`${field.value.length} / 1500 characters`}
              {...field}
            />
            {field.value.length > 1500 && (
              <FormHelperText error>Description must be no more than 1500 characters</FormHelperText>
            )}
          </>
        )}
      />

      <Controller
        name="pts_value"
        control={control}
        defaultValue=""
        rules={{
          pattern: {
            value: /^\d+$/,
            message: 'Please enter a valid positive integer or 0',
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <>
            <InputLabel htmlFor="bootstrap-input">Points Value</InputLabel>
            <TextField
              variant="outlined"
              fullWidth
              margin="normal"
              style={{ marginTop: 0, marginBottom: 20 }}
              inputProps={{ min: 0, step: 1 }}
              type="number"
              error={!!error}
              helperText={error ? error.message : null}
              {...field}
            />
          </>
        )}
      />

      <Controller
        name="category"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <>
          <InputLabel htmlFor="bootstrap-input">
          Category
          </InputLabel>
          <TextField
            variant="outlined"
            fullWidth
            margin="normal"
            style={{ marginTop: 0,marginBottom: 20}}
            {...field}
          />
          </>
        )}
      />

      <Controller
        name="dollar_value"
        control={control}
        defaultValue=""
        render={({ field: { onChange, onBlur, value }, fieldState: { error, invalid } }) => (
          <>
            <InputLabel htmlFor="bootstrap-input">Dollar Value</InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              style={{ marginTop: 0, marginBottom: 20 }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              error={invalid}
              helperText={error?.message}
            />
          </>
        )}
      />

      <Controller
        name="size"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <>
          <InputLabel htmlFor="bootstrap-input">
          Size Options - Seperate by Comma
          </InputLabel>
          <TextField
            variant="outlined"
            fullWidth
            margin="normal"
            style={{ marginTop: 0,marginBottom: 20}}
            {...field}
          />
          </>
        )}
      />

      <Controller
        name="color"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <>
          <InputLabel htmlFor="bootstrap-input">
          Color Options - Seperate by Comma
          </InputLabel>
          <TextField
            variant="outlined"
            fullWidth
            margin="normal"
            style={{ marginTop: 0,marginBottom: 20}}
            {...field}
          />
          </>
        )}
      />

      <Controller
        name="is_limited_qty"
        control={control}
        defaultValue={false}

        render={({ field }) => (
          <>
          <InputLabel id="is_limited_qty-label">Is Limited Quantity</InputLabel>
          <FormControl fullWidth margin="normal" style={{ marginTop: 0,marginBottom: 20}}>
            <Select
              labelId="is_limited_qty-label"
              {...field}
            >
              <MenuItem value={false}>No</MenuItem>
              <MenuItem value={true}>Yes</MenuItem>
            </Select>
          </FormControl>
          </>
        )}
      />

      <Controller
        name="limited_qty"
        control={control}
        defaultValue=""
        parse={(value) => parseInt(value)}
        render={({ field }) => (
          <>
          <InputLabel htmlFor="bootstrap-input">
          Number of Limited Quantity
          </InputLabel>
          <TextField
            variant="outlined"
            fullWidth
            margin="normal"
            style={{ marginTop: 0,marginBottom: 20}}
            {...field}
          />
          </>
        )}
      />

      <Controller
        name="vendor"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <>
          <InputLabel htmlFor="bootstrap-input">
          Vendor
          </InputLabel>
          <TextField
            variant="outlined"
            fullWidth
            margin="normal"
            style={{ marginTop: 0,marginBottom: 20}}
            {...field}
          />
          </>
        )}
      />

      <Controller
        name="v_item_number"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <>
          <InputLabel htmlFor="bootstrap-input">
            {` V Item Number (optional)`}
          </InputLabel>
          <TextField
            variant="outlined"
            fullWidth
            margin="normal"
            style={{ marginTop: 0,marginBottom: 20}}
            {...field}
          />
          </>
        )}
      />

    </>
  );
};


export default NewProductFields;