import React from 'react'
import { useDispatch } from 'react-redux'
import GoogleLoginButton from '../../assets/btn_google_light_normal_ios.svg'
import { oauthClient, resetToken, tokenValidation } from './auth'
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Avatar from "@mui/material/Avatar";
import LogoutIcon from '@mui/icons-material/Logout';
import { authCode, logout } from '../../utils/auth';
import { authadminlogout } from '../../actions';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 200,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    boxShadow: 24,
    p: 2,
    textAlign: 'center'
  };


export function Login() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    
    
    const handleLogin = async () => {
        await tokenValidation();
        handleOpen();
        await oauthClient.requestAuthorizationCode();
        return handleClose;
    }

    const handleBackdropClick = (e) => {
        e.stopPropagation();
        return false;
    };


    return (
        <React.Fragment>
        <Button variant='outlined' color='info' startIcon={<Avatar src={GoogleLoginButton} />} onClick={handleLogin}>Sign In With Google</Button>
        <Modal
            open={open}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            onBackdropClick={handleBackdropClick}
            // disableEscapeKeyDown
        >
            <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Logging In ...
            </Typography>
            </Box>
        </Modal>
        </React.Fragment>
    )
}



export async function GetToken() {
    try {
        await oauthClient.receiveCode();
        let token = await oauthClient.getTokens();
        return token
    } catch (error) {
        
    }

}


export function Logout() {
    const dispatch = useDispatch()
    const handleLogout = async () => {
        dispatch(authadminlogout(authCode()));
        await resetToken();
        logout()
        return window.location.replace('/');
    }

    return (
        <Button variant="outlined" color="info" onClick={handleLogout} startIcon={<LogoutIcon/>} > LOGOUT</Button>
    )
}
