import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash'
import {useSelector, useDispatch} from 'react-redux'
import { DataGrid, GridToolbarContainer} from '@mui/x-data-grid';
import { Typography } from '@mui/material';
import { Container } from '@mui/material';
import { extramileregions, extramileaddregion, extramileremoveregion, ext_updateregionsdata } from '../../../actions';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';



const columns = [
  { field: 'regions', headerName: 'Regions', width: 1000, editable: true }
]

function EditToolbar() {
  const regions = useSelector((state) => _.values(state.extregions.data))
  const [openAdd, setOpenAdd] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);
  const [removeValue, setRemoveValue] = useState("")
  const [addValue, setAddValue] = useState("")
  const [loading, setLoading] = useState(false);
  const [errorEvent, setErrorEvent] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()

  const addItem = () => {
    setOpenAdd(true);
  }

  const removeItem = () => {
    setOpenRemove(true)
  }

  const handleClose = () => {
    setOpenAdd(false);
    setOpenRemove(false);
    setRemoveValue("");
    setAddValue("");
    setErrorEvent(false)
  };

  const handleRemove = (event) => {
    setRemoveValue(event.target.value)
  }

  const handleAdd = (event) => {
    setAddValue(event.target.value)
  }

  const submitAddRegion = () => {
    if(addValue.length <= 0){
      return setErrorEvent(true)
    }
    else if(addValue.length > 0){
      setLoading(true)
      dispatch(extramileaddregion(addValue))
      setTimeout(() => {
        setAddValue("")
        dispatch(extramileregions())
        setLoading(false)
        return history.go(0)
      }, 2000);
    }
    else return setErrorEvent(true)
  };

  const submitRemoveRegion = () => {
    if(removeValue.length <= 0){
      return setErrorEvent(true)
    }
    else if(removeValue.length > 0) {
      setLoading(true)
      console.log('make API call to /api/extramile/admin/region/remove', removeValue)
      dispatch(extramileremoveregion(removeValue))
      setTimeout(() => {
        setRemoveValue("")
        dispatch(extramileregions())
        setLoading(false)
        history.go(0)
      }, 2000);
    }
    else return setErrorEvent(true)
  };



  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={addItem}>
        Add Region
      </Button>

      <Button color="primary" startIcon={<DeleteIcon />} onClick={removeItem}>
        Remove Region
      </Button>
      
      <Dialog open={openAdd} onClose={handleClose}>
        <DialogTitle>Add A Region</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Type in the name of the region you would like to add:
          </DialogContentText>
          <FormControl sx={{ m: 1, width: 500, mt: 3 }}>
            <TextField
              autoFocus
              margin="dense"
              type="text"
              fullWidth
              variant="outlined"
              defaultValue=""
              onChange={handleAdd}
              error={errorEvent}
              helperText={errorEvent ? 'Type in the new region to be added' : ''}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          {loading ? null : <Button onClick={handleClose} sx={{color:'#9b9b9b'}}>Cancel</Button>}
          <LoadingButton loading={loading} onClick={submitAddRegion} loadingIndicator="Adding Region ..." endIcon={<SendIcon />}>Submit</LoadingButton>
        </DialogActions>
      </Dialog>


      <Dialog
        fullWidth={true}
        open={openRemove}
        onClose={handleClose}
      >
        <DialogTitle>Remove Region</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select the region you would like to remove.
          </DialogContentText>
          <FormControl sx={{ m: 1, width: 500, mt: 3 }}>
          <InputLabel>Regions</InputLabel>
          <Select
            label="Regions"
            defaultValue=""
            displayEmpty
            value={removeValue}
            onChange={handleRemove}
            error={errorEvent}
            helperText={errorEvent ? 'Please select a region to be removed' : ''}
          >
            {regions.map((e) => {
              return (
                  <MenuItem key={e.id} value={e.regions}>
                      {e.regions}
                  </MenuItem>
              );
            })}
          </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          {loading ? null : <Button onClick={handleClose} sx={{color:'#9b9b9b'}}>Cancel</Button>}
          <LoadingButton loading={loading} onClick={submitRemoveRegion} loadingIndicator="Removing Region..." endIcon={<DeleteForeverIcon />}>Remove</LoadingButton>
        </DialogActions>
      </Dialog>
    </GridToolbarContainer>
  );
}


export default function ExtRegionsTable() {
  const data = useSelector((state) => state.extregions.data)
  const [load, setLoad] = useState(false)
  const [pageSize, setPageSize] = useState(10);

  const dispatch = useDispatch()

  const reloaddata = async () => {
    setLoad(true)
    dispatch(extramileregions())
    setTimeout(()=> {
      setLoad(false)
    },2500);
  }

  useEffect(() => {
    dispatch(extramileregions())
  }, [dispatch])

  const handleCellEditCommit = async (params) => {

    const row = data.find((row) =>{ return row.id === params.id });

    console.log('edited data => ',params,row)
    
    let updatedData = {
      "id": params.id,
      "prev_value":row.regions,
      "value":params.value,
      "field": params.field
    }
    console.log('NEW DATA=>',updatedData);
    dispatch(ext_updateregionsdata(updatedData));
    return reloaddata()
  };
  

  return (
      <Container maxWidth={false}>
        <Typography variant="h6" gutterBottom component="div">Extramile Regions</Typography>
        <br/>
        <Button size="small" onClick={reloaddata}>
          <RefreshIcon fontSize="medium" color='primary'/>
          Refresh Table
        </Button>
        <DataGrid
          loading={load}
          forceUpdate
          disableSelectionOnClick
          autoHeight
          density='standard'
          sx={{ overflowX: 'scroll' }}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 20,50,100]}   
          rows={data}
          getRowId={ row =>  row?.id } 
          columns={columns}
          onCellEditCommit ={(params) => handleCellEditCommit(params)}
          components={{Toolbar: EditToolbar}} 
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 }
            },
          }}
        />

      </Container>
  );
}