import {USERS_UPDATE_REQUEST ,USERS_UPDATE_RESPONSE} from '../actions';

export default function update(state = {
    isUpdating: false,
    message:'',
    status:'',
    error: null
  }, action) {
        switch (action.type) {
            case USERS_UPDATE_REQUEST:
                return { ...state, isUpdating: true}
            case USERS_UPDATE_RESPONSE:
                console.log('8cU:',action.payload)
                if(action.payload.data.status === 'success'){
                    console.log(action.payload.data)
                    return {...state, message:action.payload.data.message,status:'success', isUpdating: false}
                } else {
                    return {...state, error:action.payload.error, status:'error', isUpdating: false}
                }
            default:
                return state
        }
  }