import React, { useEffect, useState } from 'react';
import moment from 'moment'
import _ from 'lodash'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import { DataGrid, GridAddIcon, GridToolbarContainer,GridToolbarColumnsButton, GridToolbarFilterButton,GridToolbarDensitySelector, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Container, Typography, Button, Box } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogActions, FormControlLabel, Switch, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, OutlinedInput } from '@mui/material';
import { getallusers, updateuserdata } from '../../../actions';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddUserForm from '../../Forms/adduser';
import { authCode } from '../../../utils/auth';

function CustomSuperAdminStatus({ id, status, app }) {
  
  const [openDialog, setOpenDialog] = useState(false);
  const [adminStatus, setAdminStatus] = useState(status);
  const [apps, setApps] = useState(app || []);
  const history = useHistory()

  useEffect(() => {
    setAdminStatus(status)
    setApps(app)
  }, [status, app]);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setApps(
      typeof value === 'string' ? value.split(',') : value,
    );
  };


  const handleConfirm = () => {
    console.log('SUBMIT FORM =>', id, adminStatus, apps)
    let token = JSON.parse(authCode()).accessToken

    axios.put(`${process.env.REACT_APP_ROOT_URL}/user/superadmin`, { id: id, status: adminStatus, app_access: apps }, {
      headers: {
          'content-type': 'application/json',
          'Authorization': `Bearer ${token}`,
      }
    })
    .then(response => {
      if (response.status === 200) {
        return history.go()
      }
    })
    .catch(error => {
      console.error(error);
    });
  };

  return (
    <>
      <Button variant="outlined" color="primary" onClick={handleClickOpen} size="small">
        Edit SuperAdmin
      </Button>
      <Dialog open={openDialog} onClose={handleClose}>
        <DialogTitle>Edit Super Admin Status and App Access</DialogTitle>
        <DialogContent>
          <FormControlLabel
              control={<Switch checked={!!adminStatus} onChange={event => setAdminStatus(event.target.checked)} />}
              label="Super Admin Status"
          />
          <br/>
          <br/>
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="checbox-app">App Access</InputLabel>
            <Select
              labelId="checbox-app-select"
              id="checbox-app-select"
              multiple
              value={apps}
              input={<OutlinedInput label="App Access" />}
              onChange={handleChange}
              renderValue={(selected) => (selected && selected.length > 0) ? selected.join(', ') : ''}
            >
              <MenuItem value="extramile">
                <Checkbox checked={apps && apps.indexOf('extramile') > -1} />
                <ListItemText primary="Extramile" />
              </MenuItem>
              <MenuItem value="interchangeadmin">
                <Checkbox checked={apps && apps.indexOf('interchangeadmin') > -1} />
                <ListItemText primary="InterchangeAdmin" />
              </MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConfirm} variant="contained">Confirm</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}


const columns = [
  { field: 'id', headerName: 'User ID', width: 180, editable: false, hide:true },
  { field: 'workforceID', headerName: 'Workforce ID', width: 180, editable: true },
  { 
    field: 'fullname', 
    headerName: 'FullName',
    valueGetter: (value) => (value.row.name ? value.row.name.fullname : ''),
    width: 180, 
    editable: true, 
    hide: true 
  },
  {
    field: 'given_name',
    headerName: 'First Name',
    valueGetter: (value) => (value.row.name ? value.row.name.given_name : ''),
    width: 180,
    editable: true,
  },
  {
    field: 'family_name',
    headerName: 'Last Name',
    valueGetter: (value) => (value.row.name ? value.row.name.family_name : ''),
    width: 180,
    editable: true,
  },
  { field: 'email', headerName: 'Email', width: 200, editable: true, filterable: true },
  { field: 'agency', headerName: 'Agency', type:'number', width: 100, editable: true },
  { field: 'assignedAgency', headerName: 'Assigned Agency', type:'number', width: 100, editable: true },
  { field: 'unit', headerName: 'Unit', type:'number', width: 100, editable: true },
  { field: 'org_role', headerName: 'Org Role', width: 100, editable: true },
  { field: 'account_type', headerName: 'Acct Type', width: 100, editable: true, hide:true },
  {
    field: 'created_date',
    headerName: 'Created Date',
    valueGetter: (value) =>
      value.row.created_date && value.row.created_date._seconds
        ? moment.unix(value.row.created_date._seconds).format('MMM-DD-YYYY hh:mm:ss')
        : '',
    width: 180,
    editable: false,
  },
  { field: 'description', headerName: 'Description', width: 180, editable: true, hide:true },
  { 
    field: 'superadmin', 
    headerName: 'SuperAdmin',
    width:120, 
    valueGetter:(value) => _.has(value.row, 'superadmin') ? value.row.superadmin.status : ''
  },
  { field: 'app_access', headerName: 'App Access', valueGetter:(value) => _.has(value.row, 'superadmin') ? value.row.superadmin.app : '', width: 200, editable: false },
  {
    field: 'change_status',
    headerName: 'Edit SuperAdmin',
    renderCell: (params) => 
      params.row.superadmin && typeof params.row.superadmin.status !== 'undefined' ? (
        <CustomSuperAdminStatus 
          id={params.id}
          status={params.row.superadmin.status}
          app={params.row.superadmin.app}
        />
      ) : null,
    sortable: false,
    filterable: false,
    width: 150
  },
];

function EditToolbar(props) {
  const [open, setOpen] = useState(false);

  const handleAddUserClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

  };

  return (
    <GridToolbarContainer>
      <Box sx={{ flexGrow: 1 }}>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarQuickFilter />
      </Box>
      <Box>
        <Button color="primary" variant="outlined"  startIcon={<GridAddIcon />} onClick={handleAddUserClick}>
          Add User
        </Button>
        <AddUserForm open={open} handleClose={handleClose} handleAddUser={() => {}} />
      </Box>
    </GridToolbarContainer>
  );
}

// Define columns here

export default function AllUsersTable() {
  const users = useSelector((state) => state.users.data);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(20);
  const [showGrid, setShowGrid] = useState(true);

  const reloadData = async () => {
    setIsLoading(true)
    dispatch(getallusers())
    .then(() => {
      setIsLoading(false);
    })
    .catch((error) => {
      console.log(error);
      setIsLoading(false);
    });
  }

  useEffect(() => {
    if (users.length === 0) {
      setIsLoading(true);
      dispatch(getallusers());
    } else {
      return setIsLoading(false);
    }
  }, [users, dispatch]);

  const handleEditCellChangeCommitted = async (params) => {
    const { id, field, value } = params;
    const data = {
      id:id,
      field: field,
      value: value
    };
    dispatch(updateuserdata(data))
    .then(() => {
      console.log('User data updated successfully');
      reloadData();
    })
    .catch((error) => {
      console.log('Failed to update user data:', error);
    });
  };

  const handleApiError = () => {
    setShowGrid(false);
  };


  return (
    <Container maxWidth={false}>
      <Typography variant="h6" gutterBottom component="div">
        All Users Table
      </Typography>
      <br />
      <Button size="small" onClick={reloadData}>
        <RefreshIcon fontSize="medium" color="primary" />
        Refresh Table
      </Button>
      <div style={{ height: 'calc(100vh - 200px)', width: '100%' }}>
      <DataGrid
        loading={isLoading}
        onError={handleApiError}
        forceUpdate
        sx={{ overflowX: 'scroll' }}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20,50,100]} 
        rows={users}
        getRowId={ (row) => row?.id }
        columns={columns}
        onCellEditCommit ={(params) => handleEditCellChangeCommitted(params)}
        components={{
          Toolbar: EditToolbar
        }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 }
          },
        }}
      />
      </div>
    </Container>
  );
}
