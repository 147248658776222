import React from 'react'
import logo from '../assets/udot_interchange_logo.png'
import Grid from '@mui/material/Grid';


const Header = () => {
    return (
        <header className="App-header">

            <div className="header-top"></div>
            <div className="subheader">
            <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={6}>
                    <img src={logo} className="App-logo" alt="logo" />
                </Grid>
                <Grid item xs={6}>
                    <p className="subheader-title">Interchange Adminstration Application</p>
                </Grid>
            </Grid>
            </div>
        </header>
    )
}

export default Header