import React, {useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import {useSelector} from 'react-redux'
import DashboardComponent from '../containers/Dashboard/component';
import ExtramileSuperAdminFunctions from '../containers/Extramile/component';

export default function Extramile() {
        
        let history = useHistory();
        const {auth} = useSelector((state)=> state)
        
        useEffect(() => {
            setTimeout(()=> {
                console.log('AUTH=>',auth)
                if(auth.isAuthenticated){
                    return;
                }
                else {
                    return () => history.push('/')
                }
            },2500);
          }, [auth, history]);

        return (
            <div>
                    <DashboardComponent><ExtramileSuperAdminFunctions/></DashboardComponent>                  
            </div>  
        )
}