  
import {ALL_USERS_REQUEST ,ALL_USERS_SUCCESS} from '../actions';

export default function users(state = {
    isFetching: false,
    data:[],
    error: null
  }, action) {
        switch (action.type) {
            case ALL_USERS_REQUEST:
                return { ...state, isFetching: true}
            case ALL_USERS_SUCCESS:
                if(action.payload.status === 200){
                    return {...state, data:action.payload.data.data, isFetching: false}
                } else {
                    return {...state, error:action.payload.error, isFetching: false}
                }
            default:
                return state
        }
  }