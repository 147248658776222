import {combineReducers} from 'redux'
import auth from './authentication'
import users from './users'
import update from './update'
import extproducts from './extramile/products'
import extaccounts from './extramile/accounts'
import exttransactions from './extramile/transactions'
import extorders from './extramile/orders'
import extregions from './extramile/regions'
import extcreateaccounts from './extramile/createaccount'

const rootReducer = combineReducers({
    auth,
    users,
    update,
    extaccounts,
    extcreateaccounts,
    extproducts,
    exttransactions,
    extorders,
    extregions
});

export default rootReducer;