import React, {useState} from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import LayersIcon from '@mui/icons-material/Layers';
import AddRoadIcon from '@mui/icons-material/AddRoad';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import SellIcon from '@mui/icons-material/Sell';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import DomainIcon from '@mui/icons-material/Domain';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';


function ExtraMileApp() {
    const [open, setOpen] = useState(true);

    const handleClick = () => {
      setOpen(!open);
    };
  
    return (
        <React.Fragment>
        <ListItemButton onClick={handleClick}>
            <ListItemIcon>
            <AddRoadIcon />
            </ListItemIcon>
            <ListItemText primary="Extramile Admin" />
            {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
                
            <List component="div" disablePadding>

                <ListItemButton sx={{ pl: 4 }} to="/extramile/transactions">
                <ListItemIcon>
                    <KeyboardDoubleArrowRightIcon />
                </ListItemIcon>
                <ListItemText primary="Transactions" />
                </ListItemButton>
            
                <ListItemButton sx={{ pl: 4 }} to="/extramile/orders">
                <ListItemIcon>
                    <ShoppingCartIcon />
                </ListItemIcon>
                <ListItemText primary="Orders" />
                </ListItemButton>

                <ListItemButton sx={{ pl: 4 }} to="/extramile/products">
                <ListItemIcon>
                    <SellIcon />
                </ListItemIcon>
                <ListItemText primary="Products" />
                </ListItemButton>

                <ListItemButton sx={{ pl: 4 }} to="/extramile/regions">
                <ListItemIcon>
                    <DomainIcon />
                </ListItemIcon>
                <ListItemText primary="Regions" />
                </ListItemButton>




            </List>
        </Collapse>
        </React.Fragment>
    )
}

export const mainListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Apps
    </ListSubheader>
    <ListItemButton to="/dashboard">
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItemButton>
    {/* <ListItem>
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="Placeholder App 1" />
    </ListItem>
    <ListItem>
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="Placeholder App 2" />
    </ListItem> */}

  </React.Fragment>
);

export const extramileListItems = (
  <React.Fragment>
    <ExtraMileApp />
  </React.Fragment>
);

export const gasbListItems = (
  <React.Fragment>
       <ListItem>
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="GASB" />
    </ListItem>
  </React.Fragment>
);

export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Superadmin
    </ListSubheader>

    <ListItemButton to="/extramile/accounts">
        <ListItemIcon>
        <AccountBalanceWalletIcon />
        </ListItemIcon>
        <ListItemText primary="Ext Accounts" />
    </ListItemButton>

    <ListItemButton to="/extramile/employeeadmin">
      <ListItemIcon>
          <AccessibilityNewIcon />
      </ListItemIcon>
      <ListItemText primary="Ext Admins" />
    </ListItemButton>

    <ListItemButton to="/reports/extramile">
      <ListItemIcon>
        <DeveloperBoardIcon />
      </ListItemIcon>
      <ListItemText primary="Ext Functions" />
    </ListItemButton>

    <ListItemButton to="/reports/users">
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Users" />
    </ListItemButton>

  </React.Fragment>
);