import {EXT_REGIONS_REQUEST ,EXT_REGIONS_RESPONSE} from '../../actions';
import _ from 'lodash'

export default function extregions(state = {
    isFetching: false,
    message:'',
    data:[],
    error: null
  }, action) {
        switch (action.type) {
            case EXT_REGIONS_REQUEST:
                return { ...state, isFetching: true}
            case EXT_REGIONS_RESPONSE:
                var arr = _.values(action.payload.data.regions);
                
                let request = []
                arr.map((e,i) => {
                    return request.push({'id':i, 'regions':e})
                })
                if(request.length > 0){
                    return {...state, isFetching: false, data:request}
                } else {
                    return {...state, error:action.payload.error, status:'error', isFetching: false}
                }
            default:
                return state
        }
  }