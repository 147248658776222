import { useState, useEffect } from 'react';
import axios from 'axios'
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  TextField,
  Button,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import { fyquarterDB } from '../../utils/fyquarter';
import { authCode } from '../../utils/auth';
import GetColleagueInfo from '../../components/autocomplete';

/*
    TODO: Handle API call to extramile admin form to create the transaction
    ! See what acct_id needs to be passed to the form, or if system acct_id can be used
*/

const AddOrderForm =  ({ open, handleClose, handleAddOrderClick }) => {
  
    const { register, handleSubmit, reset, watch, control } = useForm();
    const [submitting, setSubmitting] = useState(false);
    let token = JSON.parse(authCode()).accessToken
    const [regions, setRegions] = useState([]);
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState({}); 
    const {auth} = useSelector((state)=> state)

    useEffect(() => {
        let token = JSON.parse(authCode()).accessToken
        let getregions = async () => {
            axios.get(`${process.env.REACT_APP_EXTRAMILE_URL}/regions`, {
              headers: {
                  'Authorization': `Bearer ${token}`
              }
            }).then((res) => {
              setRegions(res.data.regions);
            })
        }
        let getproducts = async () => { 
            await axios.get(process.env.REACT_APP_EXTRAMILE_URL + '/admin/products', {
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
              }).then((res) => {
                setProducts(res.data.products);
              })
        }
        getregions();
        getproducts();
    },[])

    const onSubmit = async (data) => {
        setSubmitting(true);
        
    try {
            let token = JSON.parse(authCode()).accessToken
            let idToken = JSON.parse(authCode()).idToken
            let profile = auth.profile
            let orderPeriod= fyquarterDB();

            let request  = await axios.get(`${process.env.REACT_APP_EXTRAMILE_URL}/user/object`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Token':`${idToken}`
                }
            })

            let acctId = request.data.data.acct_id
            console.log(profile)
            
            let formdata = [{
                "acct_id": acctId,
                "product_id":data.product.product_id,
                "orderer": {
                        "name": `${data.to.first_name} ${data.to.last_name}`,
                        "email": `${data.to.work_email}`
                },
                "points": data.product.pts_value, 
                "dollar_value": data.product.dollar_value, 
                "color": `${data.color}`, 
                "size": `${data.size}`,
                "region": `${data.memberregion}`,
                "org_number": data.to.org,
                "order_period": orderPeriod,
                "created_by": profile.workforceID
            }]
            console.log('Order Form data',formdata)   

            const response = await axios.post(`${process.env.REACT_APP_EXTRAMILE_URL}/form/admin/redeempoints`, formdata, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Token':`${idToken}`
                }
            })    
            .catch(error => console.log(error));
            console.log(response);
        
            if (response.status === 200) {
                reset();
                setSubmitting(false);
                handleClose();
                window.location.href = '/extramile/orders';
              } else {
                console.log(response.data);
              }

      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }

    };

    const handleCloseAndReset = () => {
        setSelectedProduct({}); // reset selectedProduct
        handleClose(); // call handleClose
    };

    const values = watch();

    return (
        <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>Extramile Employee Appreciation</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Admin - Give Form
                    <br/>
                    <br/>
                    This form is used by Admins to give points. Any points given using this form will show as the colleague you are giving from.
                </DialogContentText>
                <br/>

                <Controller
                    name={"product"}
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Product is required' }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <>
                            <InputLabel htmlFor="bootstrap-input">
                                Select A Product: *
                            </InputLabel>
                            <Select
                                fullWidth
                                labelId="product"
                                id="product"
                                label="product"
                                style={{ marginTop: 0,marginBottom: 20 }}
                                value={selectedProduct}
                                onChange={e => {
                                    console.log('E',e)
                                    setSelectedProduct(e.target.value);
                                    onChange(e.target.value);
                                }}
                            >
                                { products.map((option) => {
                                    return (
                                        <MenuItem key={option.product_id} value={option}>
                                            {option.product_name} <br/>
                                            {` Points: ${option.pts_value} `} 
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                            {value === '' ? <FormHelperText error sx={{marginTop:-2}}>A Product Value is Required! </FormHelperText> : null}
                            <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}><img src={value.picture_url} width="250px" height="150px"/><br/></div>
                            <i>{value.description}</i>
                        
                        </>
                    )}
                />
                
                <br/>
                <br/>

                <Controller
                    name={"color"}
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                        <>
                        <InputLabel htmlFor="bootstrap-input">
                            Type in the color that you want <br/>
                            Options:{selectedProduct.color === '' ? 'N/A' : selectedProduct.color}
                        </InputLabel>
                        <TextField
                            autoFocus
                            style={{ marginTop: 0,marginBottom: 20 }}
                            id="color"
                            type="text"
                            value={value}
                            fullWidth
                            variant="outlined"
                            onChange={onChange}
                        />
                        </>
                    )}
                />


                <Controller
                    name={"size"}
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                        <>
                        <InputLabel htmlFor="bootstrap-input">
                            Type in the size that you want <br/>
                            Options:{selectedProduct.size === "" ? 'N/A' : selectedProduct.size}
                        </InputLabel>
                        <TextField
                            autoFocus
                            style={{ marginTop: 0,marginBottom: 20 }}
                            id="color"
                            type="text"
                            value={value}
                            fullWidth
                            variant="outlined"
                            onChange={onChange}
                        />
                        </>
                    )}
                />
            
                <InputLabel htmlFor="bootstrap-input">
                    Select a colleague to redeem points <strong>FOR</strong>
                </InputLabel>
                <GetColleagueInfo name={"to"} control={control} {...register("to")}/>
                <br/>
            
                <Controller
                    name={"memberregion"}
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Member Region is required!' }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <>
                            <InputLabel htmlFor="bootstrap-input">
                                Which group is your colleague a member of ?*
                            </InputLabel>
                            <Select
                                fullWidth
                                labelId="memberregion"
                                id="memberregion"
                                label="Member Region"
                                style={{ marginTop: 0,marginBottom: 20 }}
                                value={value}
                                onChange={onChange}
                            >
                                {regions.map(e => {
                                    return (
                                        <MenuItem key={e} value={e}>
                                            {e}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                            {value === '' ? <FormHelperText error sx={{marginTop:-2}}>Member Region is required! </FormHelperText> : null}
                        </>
                    )}
                />


            </DialogContent>
            <DialogActions>
                { submitting ? null : <Button onClick={handleCloseAndReset} sx={{color:'#9b9b9b'}}>Cancel</Button> }
                <LoadingButton loading={submitting}  type="submit" loadingIndicator="Creating Transaction ..." endIcon={<SendIcon />}>Submit</LoadingButton>
            </DialogActions>
        </form>
        </Dialog>
    );
};

export default AddOrderForm;
