import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router'
import moment from 'moment'
import { useDispatch } from 'react-redux';
import { Container,Typography, Button, ButtonGroup, Collapse, Box, TextField  } from '@mui/material';
import {Dialog,DialogActions,DialogContent, DialogTitle} from '@mui/material';
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import RefreshIcon from '@mui/icons-material/Refresh';
import Stack from '@mui/material/Stack';
import { DataGrid, GridAddIcon, GridToolbarContainer,GridToolbarColumnsButton, GridToolbarFilterButton,GridToolbarDensitySelector, GridToolbarQuickFilter, GridToolbarExport } from '@mui/x-data-grid';
import { extramileaccounts, ext_updateaccounts } from '../../../actions';


const columns = [
  { field: 'workforce_id', headerName: 'Workforce ID', width: 180, editable: true },
  { field: 'acct_id', headerName: 'Account ID', width: 180, editable: true, hide:true },
  { field: 'fullname', headerName: 'FullName', width: 180, editable: true },
  { field: 'email', headerName: 'Email', width: 200, editable: true },
  { field: 'is_employee_admin', headerName: 'Employee Admin', type:'singleSelect', width: 200, editable: true,valueOptions: ["true","false"] },
  { field: 'is_voucher_admin', headerName: 'Voucher Admin', type:'singleSelect', width: 200, align:"center", editable: true,valueOptions: ["true","false"]  },
  { field: 'created_date', headerName: 'Created Date',valueGetter: (params) => moment(params.value).format('MMM-DD-YYYY'), width: 180, editable: false }
];

function EditToolbar() {
  
  const [openAddAdmin, setOpenAddAdmin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [users, setUsers] = useState([]);

  const dispatch = useDispatch()
  const history = useHistory()

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 25, // show only 25 options at a time
  });

  useEffect(() => {
    setLoading(true);
    dispatch(extramileaccounts())
      .then((response) => {
        setUsers(response.payload.data);
        console.log('userdata=>',response.payload.data, typeof response.payload.data)
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [dispatch]);

  const addAdmin = () => {
    setOpenAddAdmin(true);
  }

  const handleClose = () => {
    setOpenAddAdmin(false);
  };

  const AddEmployeeAdmin = () => {
    setLoading(true);
    console.log('add employee =>', inputValue)
    dispatch(ext_updateaccounts({id:inputValue.acct_id, field:'is_employee_admin',value:true}))
    .then(()=> {
      setLoading(false)
      history.go(0)
    })
  }

  const AddVoucherAdmin = () => {
    console.log('add voucher=>', inputValue.acct_id)
    setLoading(true);
    dispatch(ext_updateaccounts({id:inputValue.acct_id, field:'is_voucher_admin',value:true}))
    .then(()=> {
      setLoading(false)
      history.go(0)
    })
  }


  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      <Button color="primary" startIcon={<GridAddIcon />} onClick={addAdmin}>
        Add Admin
      </Button>
      <Box sx={{ flexGrow: 1 }} />
      <GridToolbarQuickFilter />
      <Dialog open={openAddAdmin} onClose={handleClose}>
    <DialogTitle>Add User To Admin Group</DialogTitle>
    <DialogContent>
    <br/>
    <Autocomplete
      freeSolo
      disablePortal
      id="admins"
      filterOptions={filterOptions}
      options={users}
      sx={{ "& .MuiAutocomplete-option": { fontSize: 10 }, width: '500px', height:'200px'}}
      getOptionLabel={(user) => {
        return `${user.fullname}\n ${user.email}`
      }}
      onChange={(event, value) => {
        console.log('input',value)
        setInputValue(value)
      }}
      renderInput={(params) => <TextField {...params} label="Users" />}
    />

    </DialogContent>
    <DialogActions>
      {loading ? null : <Button onClick={handleClose} sx={{color:'#9b9b9b'}}>Cancel</Button>}
      {/* <LoadingButton loading={loading} onClick={AddNewAdmin} loadingIndicator="Adding Account ..." endIcon={<SaveIcon />}>Add As</LoadingButton> */}
      <ButtonGroup variant="text" aria-label="text button group">
        <Button onClick={AddEmployeeAdmin}>Employee Admin</Button>
        <Button onClick={AddVoucherAdmin}>Voucher Admin</Button>
      </ButtonGroup>
    </DialogActions>
    </Dialog>
    </GridToolbarContainer>
  )
}


export default function ExtEmployeeAdmin() {

  const [pageSize, setPageSize] = useState(10);
  const [accounts, setAccounts] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch()
  const [showGrid, setShowGrid] = useState(true);

  const reloaddata = async () => {
    setIsLoading(true)
    dispatch(extramileaccounts())
    .then((data) => {
      setAccounts(data.payload.data);
      setIsLoading(false);
    })
    .catch((error) => {
      setError(error);
      setIsLoading(false);
    });
  }

  useEffect(() => {
    setIsLoading(true);
    dispatch(extramileaccounts())
      .then((data) => {
        console.log(data)
        const filteredData = data.payload.data.filter(
          (record) => record.is_employee_admin || record.isvoucher_admin
        );
        setAccounts(filteredData);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, [dispatch]);


  const handleCellEditCommit = async (params) => {
    console.log('edited data => ',params)
    dispatch(ext_updateaccounts(params));
    return
  };

  const handleApiError = () => {
    setShowGrid(false);
  };

  return (
      <Container maxWidth={false}>
        <Typography variant="h6" gutterBottom component="div">Extramile Employee & Voucher Admin</Typography>
        <br/>
        <Button size="small" onClick={reloaddata}>
          <RefreshIcon fontSize="medium" color='primary'/>
          Refresh Table
        </Button>
        <Collapse in={showGrid}>
        <div style={{ height: 'calc(100vh - 200px)', width: '100%' }}>
        <DataGrid
          loading={isLoading}
          onError={handleApiError}
          forceUpdate
          density='comfortable'
          sx={{ overflowX: 'scroll' }}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 20,50,100]} 
          rows={accounts}
          getRowId={(row) => row?.acct_id}  
          columns={columns}
          getActionsProps={(params) => ({ dispatch })} 
          onCellEditCommit ={(params) => handleCellEditCommit(params)}
          components={{
            Toolbar: EditToolbar,
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                Error connecting. Please refresh the page or try again later.
              </Stack> )
          }} 
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
        </div>
        {error}
        </Collapse>
      </Container>
  );
}