import axios from 'axios';
import { authCode } from '../utils/auth';

const ROOT_URL = process.env.REACT_APP_ROOT_URL
const EXTRAMILE_URL = process.env.REACT_APP_EXTRAMILE_URL

const LOGIN_URL =  `${ROOT_URL}/auth/google/admin/login`;
const LOGOUT_URL = `${ROOT_URL}/auth/google/logout`;

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';

export const ALL_USERS_REQUEST = 'ALL_USERS_REQUEST'
export const ALL_USERS_SUCCESS = 'ALL_USERS_SUCCESS'
export const USERS_UPDATE_REQUEST = 'USERS_UPDATE_REQUEST'
export const USERS_UPDATE_RESPONSE = 'USERS_UPDATE_RESPONSE'

export const EXT_ACCOUNTS_REQUEST = 'EXT_ACCOUNTS_REQUEST'
export const EXT_ACCOUNTS_RESPONSE = 'EXT_ACCOUNTS_RESPONSE'
export const EXT_UPDATE_ACCOUNTS_REQUEST = 'EXT_REMOVE_ACCOUNTS_REQUEST'
export const EXT_UPDATE_ACCOUNTS_RESPONSE = 'EXT_REMOVE_ACCOUNTS_RESPONSE' 
export const EXT_ACCOUNTS_DUP_REQUEST = 'EXT_ACCOUNTS_DUP_REQUEST'
export const EXT_ACCOUNTS_DUP_RESPONSE = 'EXT_ACCOUNTS_DUP_RESPONSE'
export const EXT_ACCOUNTS_CREATE_REQUEST = 'EXT_ACCOUNTS_CREATE_REQUEST'
export const EXT_ACCOUNTS_CREATE_RESPONSE = 'EXT_ACCOUNTS_CREATE_RESPONSE'
export const EXT_CALC_TOTAL_REQ = 'EXT_CALC_TOTAL_REQ'
export const EXT_CALC_TOTAL_RES = 'EXT_CALC_TOTAL_RES'

export const EXT_PRODUCTS_REQUEST = 'EXT_PRODUCTS_REQUEST'
export const EXT_PRODUCTS_RESPONSE = 'EXT_PRODUCTS_RESPONSE'

export const EXT_TRANSACTIONS_REQUEST = 'EXT_TRANSACTIONS_REQUEST'
export const EXT_TRANSACTIONS_RESPONSE = 'EXT_TRANSACTIONS_RESPONSE'

export const EXT_ORDERS_REQUEST = 'EXT_ORDERS_REQUEST'
export const EXT_ORDERS_RESPONSE = 'EXT_ORDERS_RESPONSE'


export const EXT_REGIONS_REQUEST = 'EXT_REGIONS_REQUEST'
export const EXT_REGIONS_RESPONSE = 'EXT_REGIONS_RESPONSE'
export const EXT_ADD_REGIONS_REQUEST = 'EXT_ADD_REGIONS_REQUEST'
export const EXT_ADD_REGIONS_RESPONSE = 'EXT_ADD_REGIONS_RESPONSE'
export const EXT_REMOVE_REGIONS_REQUEST = 'EXT_REMOVE_REGIONS_REQUEST'
export const EXT_REMOVE_REGIONS_RESPONSE = 'EXT_REMOVE_REGIONS_RESPONSE'
export const EXT_UPDATE_REGIONS_REQUEST = 'EXT_REMOVE_REGIONS_REQUEST'
export const EXT_UPDATE_REGIONS_RESPONSE = 'EXT_REMOVE_REGIONS_RESPONSE'




export function authadminlogin(data) {
    return async (dispatch) => {
        dispatch({type:LOGIN_REQUEST});
        try {
            const response = await axios.post(`${LOGIN_URL}`, data, {
                headers: {
                    'content-type': 'application/json'
                }
            });
            return dispatch({
                type: LOGIN_SUCCESS,
                payload: response
            });
        } catch (error) {
            return console.log(error);
        }
    }
}

export function authadminlogout(data) {
    return async (dispatch) => {
        let token = JSON.parse(authCode()).accessToken
        dispatch({type:LOGOUT});
        axios.post(`${LOGOUT_URL}`,data, {
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        }).catch(error => console.log(error))   
    }
}

export function getallusers() {
    return async (dispatch) => {
        let token = JSON.parse(authCode()).accessToken
        dispatch({type:ALL_USERS_REQUEST});
        try {
            const response = await axios.get(`${ROOT_URL}/user/all`, {
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });
            return dispatch({
                type: ALL_USERS_SUCCESS,
                payload: response
            });
        } catch (error) {
            return console.log(error);
        }
    }
}

export function updateuserdata(data) {
    return async (dispatch) => {
        let token = JSON.parse(authCode()).accessToken
        dispatch({type:USERS_UPDATE_REQUEST});
        try {
            const response = await axios.post(`${ROOT_URL}/user/update`,data, {
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });
            return dispatch({
                type: USERS_UPDATE_RESPONSE,
                payload: response
            });
        } catch (error) {
            return console.log(error);
        }
    }
}

export function extramileaccounts() {
    return async (dispatch) => {
        let token = JSON.parse(authCode()).accessToken
        dispatch({type:EXT_ACCOUNTS_REQUEST});
        try {
            const response = await axios.get(`${EXTRAMILE_URL}/admin/accounts`, {
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });
            return dispatch({
                type: EXT_ACCOUNTS_RESPONSE,
                payload: response
            });
        } catch (error) {
            return console.log(error);
        }
    }
}

export function extramileproducts() {
    return async (dispatch) => {
        let token = JSON.parse(authCode()).accessToken
        dispatch({type:EXT_PRODUCTS_REQUEST});
        try {
            const response = await axios.get(`${EXTRAMILE_URL}/admin/products`, {
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });
            return dispatch({
                type: EXT_PRODUCTS_RESPONSE,
                payload: response
            });
        } catch (error) {
            return console.log(error);
        }
    }
}

export function extramileorders() {
    return async (dispatch) => {
        let token = JSON.parse(authCode()).accessToken
        dispatch({type:EXT_ORDERS_REQUEST});
        try {
            const response = await axios.get(`${EXTRAMILE_URL}/admin/orders`, {
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });
            return dispatch({
                type: EXT_ORDERS_RESPONSE,
                payload: response
            });
        } catch (error) {
            return console.log(error);
        }
    }
}

export function extramileregions() {
    return async (dispatch) => {
        let token = JSON.parse(authCode()).accessToken
        dispatch({type:EXT_REGIONS_REQUEST});
        try {
            const response = await axios.get(`${EXTRAMILE_URL}/regions`, {
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });
            return dispatch({
                type: EXT_REGIONS_RESPONSE,
                payload: response
            });
        } catch (error) {
            return console.log(error);
        }
    }
}

export function extramileaddregion(region) {
    return async (dispatch) => {
        let token = JSON.parse(authCode()).accessToken
        dispatch({type:EXT_ADD_REGIONS_REQUEST});
        try {
            const response = await axios.post(`${EXTRAMILE_URL}/admin/region/add`,{'region':region}, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            return dispatch({
                type: EXT_ADD_REGIONS_RESPONSE,
                payload: response
            });
        } catch (error) {
            return console.log(error);
        }
    }
}

export function extramileremoveregion(region) {
    return async (dispatch) => {
        let token = JSON.parse(authCode()).accessToken
        dispatch({type:EXT_REMOVE_REGIONS_REQUEST});
        try {
            const response = await axios.delete(`${EXTRAMILE_URL}/admin/region/remove`,{
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                data:{'region':region}
            });
            return dispatch({
                type: EXT_REMOVE_REGIONS_RESPONSE,
                payload: response
            });
        } catch (error) {
            return console.log(error);
        }
    }
}

export function ext_updateregionsdata(updateregion) {
    return async (dispatch) => {
        let token = JSON.parse(authCode()).accessToken
        dispatch({type:EXT_UPDATE_REGIONS_REQUEST});
        try {
            const response = await axios.post(`${EXTRAMILE_URL}/admin/region/update`, updateregion, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
               
            });
            return dispatch({
                type: EXT_UPDATE_REGIONS_RESPONSE,
                payload: response
            });
        } catch (error) {
            return console.log(error);
        }
    }
}

export function ext_updateaccounts(data) {
    return async (dispatch) => {
        let token = JSON.parse(authCode()).accessToken
        console.log('8uA', data)
        dispatch({type:EXT_UPDATE_ACCOUNTS_REQUEST});
        try {
            const response = await axios.post(`${EXTRAMILE_URL}/admin/accounts/update`, data, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
               
            });
            return dispatch({
                type: EXT_UPDATE_ACCOUNTS_RESPONSE,
                payload: response
            });
        } catch (error) {
            return console.log(error);
        }
    }
}

export function ext_acctdupcheck(data) {
    return async (dispatch) => {
        let token = JSON.parse(authCode()).accessToken
        dispatch({type:EXT_ACCOUNTS_DUP_REQUEST});
        try {
            const response = await axios.post(`${EXTRAMILE_URL}/admin/accounts/duplicatecheck`, 
            {
                workforce_id:data.workforceID,
                email: data.email
            },
            {
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });
            return dispatch({
                type: EXT_ACCOUNTS_DUP_RESPONSE,
                payload: response
            });
        } catch (error) {
            return console.log(error);
        }
    }
}

export function ext_createacct(data) {
    return async (dispatch) => {
        let token = JSON.parse(authCode()).accessToken
        dispatch({type:EXT_ACCOUNTS_CREATE_REQUEST});
        try {
            const response = await axios.post(`${EXTRAMILE_URL}/admin/account/createaccount`, 
            {
                workforce_id:data.workforceID,
                email: data.email,
                fullname:data.name.fullname
            },
            {
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });
            return dispatch({
                type: EXT_ACCOUNTS_CREATE_RESPONSE,
                payload: response
            });
        } catch (error) {
            return console.log(error);
        }
    }
}

export function ext_calctotal(accountId) {
    return async (dispatch) => {
        let token = JSON.parse(authCode()).accessToken
        dispatch({type:EXT_CALC_TOTAL_REQ});
        try {
            const response = await axios.get(`${EXTRAMILE_URL}/admin/accounts/calculatetotal?acct_id=${accountId}`, 
            {
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });
            return dispatch({
                type: EXT_CALC_TOTAL_RES,
                payload: response
            });
        } catch (error) {
            return console.log(error);
        }
    }
}