import React, { useState } from 'react';
import axios from 'axios';
import { Container, Card, Box, Typography, Button, Dialog, Alert, Snackbar  } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import { authCode } from '../../utils/auth';

export default function ExtramileSuperAdminFunctions() {

  const [open, setOpen] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');

  const handlePointsJob = async () => {
    try {
    
      setLoading(true);
      let token = JSON.parse(authCode()).accessToken

      const response = await axios.post(process.env.REACT_APP_EXTRAMILE_URL + '/admin/accounts/giveable/set', 
      {
          headers: {
              'content-type': 'application/json',
              'Authorization': `Bearer ${token}`,
          }
      });

      console.log(response)

      if(response.status === 200) {
        setMessage('Job run successful with the following comments:' + response.data.message);
        setSeverity('success');
        setShowAlert(true)
      } else {
        setMessage('Job run could not be performed. Try again later, if it fails continously reach out to the development team.');
        setSeverity('error');
        setShowAlert(true);
      }

    }
    catch (error) {
      setMessage('Job run could not be performed. Try again later, if it fails continously reach out to the development team.');
      setSeverity('error');
      setShowAlert(true);

    } finally {
      setLoading(false);
    }
  }

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowAlert(false);
  };


  return (
    <Container>
      <Box sx={{ width:'100%', height: '70vh', backgroundColor:'white', padding:'45px' }}>
        <Typography variant="h5" >
          Extramile Superadmin Functions
        </Typography>
        <br/>
        <Card variant="outlined" sx={{ maxWidth: '350px' }}>
          <CardContent>
            
            <Typography variant="h6" component="div" sx={{ mb: 2.0}}>
              Yearly Give Point Function
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 5.0}}>
              There is a system CRON job that is set on Google Cloud Scheduler to assign 4 points at 12.01am on July 1st every year. 
            </Typography>

            <Alert severity="error">Only use this function to give 4 points to everyone if the CRON Job failed.</Alert>
          </CardContent>
          <CardActions>
            <Button onClick={handleOpen}>Give 4 Points To All </Button>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"System did not assign 4 points?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Typography variant="body2" color="text.secondary" sx={{ mt: 3.0}}>
                    If the system has not assigned 4 points to all users on July 1, you can use this function to assign 4 <strong>Giveable</strong> points to all Extramile user.
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mt: 2.0}}>
                    System/Cron/GCP Check: Nathan Peterson (DTS)
                    <br/>
                    Product Owners : Keriann Noble(UDOT) or Amber Mortensen (UDOT)
                    <br/>
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mt: 3.0}}>
                    <Alert severity="info">{`Going to Extramile > Accounts is one way to check if the system has successfully assigned 4 Giveable points`}</Alert>
                  </Typography>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                {loading ? null : <Button onClick={handleClose} sx={{color:'#9b9b9b'}}>Cancel</Button>}
                <LoadingButton loading={loading} onClick={handlePointsJob} loadingIndicator="Job Running ..." endIcon={<ScheduleSendIcon />}>Run Job</LoadingButton>
                <Snackbar open={showAlert} autoHideDuration={5000} onClose={handleAlertClose}>
                  <Alert onClose={handleAlertClose} severity={severity}>
                    {message}
                  </Alert>
                </Snackbar>
              </DialogActions>
            </Dialog>
          </CardActions>
        </Card>
      </Box>
    </Container>
  );
}