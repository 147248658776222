import {OAuth2AuthCodePkceClient} from 'oauth2-pkce';

const AUTH_API = process.env.REACT_APP_AUTH_API

export const oauthClient = new OAuth2AuthCodePkceClient({
    scopes: ["openid","profile","email", "work_profile"],
    authorizationUrl: AUTH_API + '/authorize',
    tokenUrl: process.env.REACT_APP_TOKEN_URL,
    clientId: process.env.REACT_APP_CLIENT_ID,
    redirectUrl: process.env.REACT_APP_REDIRECT_URL,
    storeRefreshToken: false,
    // optional:
    onAccessTokenExpiry() {
        // when the access token has expired
        return oauthClient.exchangeRefreshTokenForAccessToken();
    },
    onInvalidGrant() {
        // when there is an error getting a token with a grant
        console.warn('Invalid grant! Auth code or refresh token need to be renewed.');
        // you probably want to redirect the user to the login page here
    },
    onInvalidToken() {
        // the token is invalid, e. g. because it has been removed in the backend
        console.warn('Invalid token! Refresh and access tokens need to be renewed.');
        // you probably want to redirect the user to the login page here
    }
});

export const tokenValidation = async () => {
    let tokenCheck = await oauthClient.isAuthorized();
    return tokenCheck;
}

export const authorizationCode = async () => {
    let authCode = await oauthClient.requestAuthorizationCode();
    return authCode;
}

export const getToken = async () => {
    oauthClient.receiveCode()
    return await oauthClient.getTokens();
}

export const resetToken = async () => {
    return await oauthClient.reset();
}


