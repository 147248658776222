import {EXT_ORDERS_REQUEST ,EXT_ORDERS_RESPONSE} from '../../actions';
// import _ from 'lodash'

export default function extorders(state = {
    isFetching: false,
    message:'',
    data:[],
    error: null
  }, action) {
        switch (action.type) {
            case EXT_ORDERS_REQUEST:
                return { ...state, isFetching: true}
            case EXT_ORDERS_RESPONSE:                             
                if(action.payload.data.rows.length > 0){
                    return {...state, isFetching: false, data:action.payload.data.rows}
                } else {
                    return {...state, error:action.payload.error, status:'error', isFetching: false}
                }
            default:
                return state
        }
  }